export const GET_TIME_DETAILS_REQUEST = "GET_TIME_DETAILS_REQUEST"
export const GET_TIME_DETAILS_SUCCESS = "GET_TIME_DETAILS_SUCCESS"
export const GET_TIME_DETAILS_FAILURE = "GET_TIME_DETAILS_FAILURE"

export const GET_CALENDAR_DATA_REQUEST = "GET_CALENDAR_DATA_REQUEST"
export const GET_CALENDAR_DATA_SUCCESS = "GET_CALENDAR_DATA_SUCCESS"
export const GET_CALENDAR_DATA_FAILURE = "GET_CALENDAR_DATA_FAILURE"

export const GET_TIME_RECORDS_REQUEST = "GET_TIME_RECORDS_REQUEST"
export const GET_TIME_RECORDS_SUCCESS = "GET_TIME_RECORDS_SUCCESS"
export const GET_TIME_RECORDS_FAILURE = "GET_TIME_RECORDS_FAILURE"

export const GET_TIME_OFF_RECORDS_REQUEST = "GET_TIME_OFF_RECORDS_REQUEST"
export const GET_TIME_OFF_RECORDS_SUCCESS = "GET_TIME_OFF_RECORDS_SUCCESS"
export const GET_TIME_OFF_RECORDS_FAILURE = "GET_TIME_OFF_RECORDS_FAILURE"

export const GET_TIME_RECORD_TEMPLATES_REQUEST = "GET_TIME_RECORD_TEMPLATES_REQUEST"

export const GET_TIME_RECORD_TEMPLATES_SUCCESS = "GET_TIME_RECORD_TEMPLATES_SUCCESS"

export const GET_TIME_RECORD_TEMPLATES_FAILURE = "GET_TIME_RECORD_TEMPLATES_FAILURE"

