import { GET_TIME_OFF_USAGE_INFO_FAILURE } from "../../TimeOffBalances/const"
import {
    GET_TIME_RECORDS_REQUEST,
    GET_TIME_RECORDS_SUCCESS,
    GET_TIME_RECORDS_FAILURE,
    GET_TIME_OFF_RECORDS_SUCCESS,
    GET_TIME_OFF_RECORDS_REQUEST
} from "../const"
    
const initialState = {
    items:[],
    loading: false,
    timeOffRecords: [],
}
  
const handlers = {
    [GET_TIME_RECORDS_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_TIME_RECORDS_SUCCESS]: (state, { payload: { timeRecords } }) => ({
        ...state,
        ...timeRecords,
        loading: false,
    }),
    [GET_TIME_RECORDS_FAILURE]: (state) => ({ ...state, loading: false }),

    [GET_TIME_OFF_RECORDS_SUCCESS]: (state, {payload: {timeOffRecords}}) => ({...state, timeOffRecords, loading: false}),
    [GET_TIME_OFF_RECORDS_REQUEST]: (state) => ({...state, loading: true}),
    [GET_TIME_OFF_USAGE_INFO_FAILURE]: (state) => ({...state, loading: false}),

    
    DEFAULT: (state) => state,
}

const timeRecordsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default timeRecordsReducer
  

