import {
    GET_NEXT_PAGE_PROJECTS_REQUEST,
    GET_PROJECTS_FAILURE,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
} from "../const"


const initialState = {
    projects: [],
    total: 0,
    tableLoading: true,
    loading: true,
}

const handlers = {
    [GET_NEXT_PAGE_PROJECTS_REQUEST]: (state) => ({...state, tableLoading: true}),
    [GET_PROJECTS_SUCCESS]: (state, {payload: {projects}}) => ({
        ...state,
        total: projects.total,
        projects: [...state.projects, ...projects.items],
        tableLoading: false,
        loading: false,
    }),
    [GET_PROJECTS_FAILURE]: (state) => ({...state, tableLoading: false, loading: false}),
    [GET_PROJECTS_REQUEST]: (state) => ({...state, loading: true, projects: [], total: 0}),
    DEFAULT: (state) => state,
}


const projectsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default projectsReducer
