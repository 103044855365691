import {getReportsChart, getReportsTable, getSearchReports, getTotalReports,} from "../api"
import {
    GET_REPORTS_DATA_FAILURE,
    GET_REPORTS_DATA_REQUEST,
    GET_REPORTS_DATA_SUCCESS,
    GET_REPORTS_TABLE_FAILURE,
    GET_REPORTS_TABLE_REQUEST,
    GET_REPORTS_TABLE_SUCCESS,
    GET_USERS_REPORTS,
    SET_SELECTED_USER
} from "../const"

const success = (tableData, salaryData, overtimeData, bonusesData, chartData) => ({
    type: GET_REPORTS_DATA_SUCCESS,
    payload: { tableData, salaryData, overtimeData, bonusesData, chartData },
})

const request = () => ({ type: GET_REPORTS_DATA_REQUEST })

const failure = () => ({ type: GET_REPORTS_DATA_FAILURE })

export const setSelectedUser = (selectedUser) => ({
    type: SET_SELECTED_USER,
    payload: {selectedUser}
})

const searchUsers = (users) => ({
    type: GET_USERS_REPORTS,
    payload: { users },
})

const tableRequest = () => ({ type: GET_REPORTS_TABLE_REQUEST })

const tableSuccess = (tableData) => ({
    type: GET_REPORTS_TABLE_SUCCESS,
    payload: { tableData },
})

const tableFailure = () => ({ type: GET_REPORTS_TABLE_FAILURE })


const getNotNullOvertimes = (overtimes) => {
    return overtimes.filter(overtime => overtime.amount > 0)
}

export const receiveReportsData = (userId, withSalary, params, group_by, cancelToken) => async (dispatch) => {
    dispatch(request())
    
    Promise.all([
        getReportsTable(userId, {...params, ...group_by}, cancelToken),
        getReportsChart(userId, params, cancelToken),
        withSalary && getTotalReports(userId, params, cancelToken),
    ])
        .then(([{data: tableData}, {data: chartData}, report]) => {
            const salaryData = !report?.data?.salary ? [] : report?.data?.salary
            const overtimeData = !report?.data?.overtime ? [] : report?.data?.overtime
            const bonusesData = !report?.data?.bonus ? [] : report?.data?.bonus

            dispatch(success(tableData, salaryData, getNotNullOvertimes(overtimeData), bonusesData, chartData))
        })
        .catch(() => dispatch(failure()))
}

export const receiveReportsSearch = (find, cancelToken) => async (dispatch) => {
    try {
        const { data: receiveEmployeesData } = find !== "" ?  await getSearchReports(find, cancelToken) : {data: []}     
        dispatch(searchUsers(receiveEmployeesData))
    } catch (e) {
        console.log(e)
    }
}

export const receiveReportsTable = (userId, params, cancelToken) => async (dispatch) => {
    dispatch(tableRequest())
    try {
        const { data: tableData } = await getReportsTable(userId, params, cancelToken)    

        dispatch(tableSuccess(tableData))
    } catch (e) {
        console.log(e)
        dispatch(tableFailure())
    }
}
