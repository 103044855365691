import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveEmployeePositions} from "../actions/EmployeePositionsActions"
import {createEmployeePosition} from "../api"

import CreatableDDList from "../../../toolkits/CreatableDDList/CreatableDDList"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


export const EmployeePositionsDDList = ({setPosition, setUserMessage, ...props}) => {
    const dispatch = useDispatch()

    const employeePositions = useSelector((state) => state.employeePositions.employeePositions)


    useEffect(() => {
        dispatch(receiveEmployeePositions())
    }, [])

    return (
        <CreatableDDList
            text_label="Position"
            placeholder=""
            listOptions={employeePositions.map((position) => ({value: position.id, label: position.name}))}
            onChange={position => {setPosition({value: position.value, label: position.label})}}
            onCreateOption={(new_option) => {
                createEmployeePosition({name: new_option})
                    .then((response) => {
                        setPosition({value: response.data.id, label: response.data.name})
                        dispatch(receiveEmployeePositions())
                        setUserMessage({message: "Employee Position was successfully created!", code: SUCCESS_CODE})
                    })
                    .catch(error => {
                        console.log(error)
                        setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                    })
            }}
            {...props}
        />
    )
}

export default EmployeePositionsDDList
