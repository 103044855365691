import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {Bar} from "react-chartjs-2"
import MultipleSelect from "../../../toolkits/Multiselect"

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from "chart.js"
import {
    addMonths,
    addYears,
    endOfMonth,
    endOfWeek,
    endOfYear,
    format,
    isBefore,
    startOfMonth,
    startOfWeek,
    startOfYear
} from "date-fns"

import CustomDate from "../../../components/CustomDate"

import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"

import {getSavedReportsRange, saveReportsRange} from "../../../utils/localstorage.utils"
import {
    formatDate,
    daysDifference,
    isStartOfYear,
    isEndOfYear,
    isEndOfMonth,
} from "../../../utils/datetime.utils"
import Protected from "../../../components/Protected/Protected"
import {DD_MM_YYYY, DESKTOP_WIDTH, FILTER_BY_PROJECTS_NOTE, FILTER_TYPES, HOURS_TYPE} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"
import { getColorByHoursType } from "../../../utils/other.utils"
import InfoMessage from "../../../components/InfoMessage"
import { useMediaQuery } from "react-responsive"


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

const TrackerChart = ({
    isEmptyChart,
    date,
    setDate,
    defaultDate,
    user,
    projects,
    selectedProjectIds,
    setSelectedProjectIds,
    setShowDayTimeRecordsPopup,
    setChosenDate
}) => {
    const savedReportsRange = getSavedReportsRange(user)
    const [active, setActive] = useState(Object.values(FILTER_TYPES).includes(savedReportsRange) ? savedReportsRange : FILTER_TYPES.THIS_MONTH)
    const {
        chart_data,
        working_days,
        loading,
    } = useSelector(state => state.reportsPage)
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})
    const hasProjects = projects.length !== 0


    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth()

    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1)
    const weekStart = startOfWeek(now, {weekStartsOn: 1})
    const weekEnd = endOfWeek(now, {weekStartsOn: 1})
    const monthStart = startOfMonth(now)
    const monthEnd = endOfMonth(now)
    const lastMonthStart = startOfMonth(lastMonth)
    const lastMonthEnd = endOfMonth(lastMonth)
    const yearStart = startOfYear(now)
    const yearEnd = endOfYear(now)

    const lastYear = new Date(now)
    lastYear.setFullYear(now.getFullYear() - 1)
    const lastYearStart = startOfYear(lastYear)
    const lastYearEnd = endOfYear(lastYear)

    const dayName = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
    const monthName = ["Jan", "Fab", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

    const getRangeDates = () => {
        const diff = daysDifference(date.created_after, date.created_before)

        if (isStartOfYear(date.created_after) && isEndOfYear(date.created_before) &&
            date.created_after.getFullYear() !== date.created_before.getFullYear()
        ) {
            return `${formatDate(date.created_after, "YYYY")} - ${formatDate(date.created_before, "YYYY")}`
        }

        if (date.created_after.getDate() === 1) {
            if (isEndOfMonth(date.created_before) && (
                date.created_after.getMonth() !== date.created_before.getMonth() ||
                date.created_after.getFullYear() !== date.created_before.getFullYear()
            )) {
                return (date.created_after.getFullYear() === currentYear && date.created_before.getFullYear() === currentYear)
                    ? `${formatDate(date.created_after, "MMMM")} - ${formatDate(date.created_before, "MMMM")}`
                    : `${formatDate(date.created_after, "MMMM YYYY")} - ${formatDate(date.created_before, "MMMM YYYY")}`
            }
        }

        return `${formatDate(date.created_after, DD_MM_YYYY)}${
            diff > 0 ? ("-" + formatDate(date.created_before, DD_MM_YYYY)) : ""}`
    }

    const  getAllDaysInWeek = (startDate, endDate) => {
        const date = new Date(startDate.getTime())
        const dates = []
        const dayName = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

        while (date <= endDate) {
            dates.push([dayName.shift(), formatDate(date, "DD")])
            date.setDate(date.getDate() + 1)
        }

        return dates
    }

    const getAllDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1)
        const dates = []

        while (date.getMonth() === month) {
            dates.push([`${dayName[date.getDay()]}`, `\n${formatDate(date, "DD")}`])
            date.setDate(date.getDate() + 1)
        }
        if (dates.length === 32 && dates[dates.length - 1] === "") {
            dates.pop()
        }

        return dates
    }

    const prioritizeTypes = (data) => {
        const prioritizedTypes = [HOURS_TYPE.DayOff, HOURS_TYPE.UnpaidDayOff, HOURS_TYPE.Illness]
        const types = data.split(",")
    
        for (let i = 0; i < types.length; i++) {
            const type = types[i].trim()
            if (prioritizedTypes.includes(type)) {
                return [ type ]
            }
        }
    
        return types
    }
    
    const getCustomLabels = (start, end) => {
        const date = new Date(start.getTime())
        const difference = (end.getTime() - start.getTime()) / (1000 * 3600 * 24)
        const dates = []

        if (difference <= 31) {
            while (date <= end) {
                dates.push([`${dayName[date.getDay()]}`, `\n${formatDate(date, "DD")}`])
                date.setDate(date.getDate() + 1)
            }
            return dates
        }
        if (difference < 366) {
            while (isBefore(start, end)) {
                dates.push(format(start, "MMM"))
                start = addMonths(start, 1)
            }
            return dates
        }
        if(difference > 366) {
            while (isBefore(start, end)) {
                dates.push(format(start, "yyyy"))
                start = addYears(start, 1)
            }
            return dates
        }
    }

    const chooseDate = (name) => {
        switch (name) {
        case FILTER_TYPES.THIS_WEEK:
            return [{created_after: weekStart, created_before: weekEnd}, getAllDaysInWeek(weekStart, weekEnd), 2]
        case FILTER_TYPES.THIS_MONTH:
            return [{created_after: monthStart, created_before: monthEnd}, getAllDaysInMonth(currentYear, currentMonth), 2]
        case FILTER_TYPES.LAST_MONTH:
            return [
                {created_after: lastMonthStart, created_before: lastMonthEnd}, 
                currentMonth === 0 ? getAllDaysInMonth(currentYear - 1, 11) : getAllDaysInMonth(currentYear, currentMonth - 1) ,
                2
            ]
        case FILTER_TYPES.THIS_YEAR:
            return [{created_after: yearStart, created_before: yearEnd}, monthName, 36]
        case FILTER_TYPES.LAST_YEAR:
            return [{created_after: lastYearStart, created_before: lastYearEnd}, monthName, 36]
        default:
            return [{created_after: defaultDate.created_after, created_before: defaultDate.created_before},
                getCustomLabels(defaultDate.created_after, defaultDate.created_before)]
        }
    }

    const MenuItem = ({name, type, active, setActive}) => (
        <span 
            onClick={() => {
                if (type !== FILTER_TYPES.CUSTOM){
                    saveReportsRange(type, user)
                }
                setActive(type)
                setDate(chooseDate(type)[0])
            }}
            className={`reports-menu-item ${active === type ? "reports-menu-active t-s3" : "t-s4"}`}
        >
            {name}
        </span>
    )

    useEffect(() => {
        setDate(chooseDate(active)[0])
    }, [])

    const onChartClick = (event, elements) => {
        if (elements.length === 0) return

        const clickedElementIndex = elements[0].index
        const clickedBarDate = chart_data.data[clickedElementIndex].date
        setChosenDate(clickedBarDate)

        const isDayPeriod = chart_data.type == "day"

        if (isDayPeriod) {
            setShowDayTimeRecordsPopup(true)
        }
    }

    const onChartHover = (event, chartElement) => {
        const chart = chartElement[0]
        if (chart && chart_data.type === "day") {
            event.native.target.style.cursor = "pointer"
        } else {
            event.native.target.style.cursor = "default"
        }
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: onChartClick,
        onHover: onChartHover,
        plugins : {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        const value = context.raw
                        const hours = Math.floor(value)
                        const minutes = Math.round((value - hours) * 60)
                        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`
                    }
                }
            },
        },
        layout: {
            padding: {
                right: 22,
                top: 26,
                bottom: 8,
                left: 22,
            },
        },
        scales: {
            y: {
                position: "right",
                grid: {
                    drawBorder: false,
                    color: loading ? "#FFFFFF" : "#EAE9E9",
                },
                ticks: {
                    stepSize: isEmptyChart ? 0.2 : chooseDate(active)[2],
                    color: "#999999",
                    callback: (value) => `${value} h`,
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: false,
                    drawOnChartArea: false,
                },

                ticks: {
                    color: loading ? "#999999" : "#37474F",
                }
            },
        },
    }

    const data = {
        labels: chooseDate(active)[1],
        datasets: [
            {
                data: chart_data.data.map(work => work.total),
                backgroundColor: loading ? "#FFFFFF" : chart_data.data.map((work) => 
                    work.type && chart_data.type == "day" ? getColorByHoursType(prioritizeTypes(work.type)[0]) : "#F57F17"),
                borderRadius: 5,
                barThickness: active === "This week" ? 200 : "flex",
                color: "white",
            },
        ],
    }
    return (
        <div className="scrollable-div">
            <div className="reports-chart">
                <div className="reports-menu" style={loading ? {pointerEvents: "none"} : {}}>
                    <span className="reports-menu-item-period t-s4">Period:</span>
                    <MenuItem name='This week' type={FILTER_TYPES.THIS_WEEK} active={active} setActive={setActive} />
                    <MenuItem name='This month' type={FILTER_TYPES.THIS_MONTH} active={active} setActive={setActive} />
                    <MenuItem name='Last month' type={FILTER_TYPES.LAST_MONTH} active={active} setActive={setActive} />
                    <MenuItem name='This year' type={FILTER_TYPES.THIS_YEAR} active={active} setActive={setActive} />
                    <MenuItem name='Last year' type={FILTER_TYPES.LAST_YEAR} active={active} setActive={setActive} />
                    <CustomDate name='Custom' type={FILTER_TYPES.CUSTOM} setCustom={setDate} active={active} setActive={setActive}/>

                    {isDesktop && hasProjects && <div className="reports-menu-project">
                        {selectedProjectIds.length !== 0 && selectedProjectIds.length !== projects.length &&
                        <InfoMessage text={FILTER_BY_PROJECTS_NOTE} />}
                        { projects.length > 1 &&
                            <>
                                <span className='reports-menu-project-text t-s4'>Project: </span>
                                <Protected permissions={[PERMISSIONS.READ_OWN_PROJECT, PERMISSIONS.READ_PROJECT]}>
                                    <MultipleSelect
                                        loading={loading}
                                        placeholder="Choose the project"
                                        options={projects.map(p => p.project)}
                                        selectedOptionIds={selectedProjectIds}
                                        setSelectedOptionIds={setSelectedProjectIds}
                                    />
                                </Protected>
                            </>
                        }
                    </div>}

                    {!loading &&
                        <div className="reports-menu-item reports-menu-range t-s4">
                            <span className="reports-menu-range-date t-h3">
                                {getRangeDates()}
                            </span>
                            <span className="reports-menu-range-days t-b3">
                                {`${working_days} WORKING DAY${working_days === 1 ? "" : "S"}`}
                            </span>
                        </div>
                    }
                </div>
                <div className={`reports-chart-block ${loading ? "loading-chart" : ""} ${isEmptyChart ? "empty-chart" : ""}`}>
                    <Bar options={options} data={data} />

                    {loading ?
                        <div className="reports-chart-progress">
                            <StyledCircularProgress/>
                        </div> : isEmptyChart && 
                        <div className="reports-chart-empty t-h1">
                            Empty Result
                        </div>
                    }
                </div>
                
            </div>
        </div>
    )
}
 
export default TrackerChart