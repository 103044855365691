import {
    GET_USER_TIME_RECORDS_REQUEST,
    GET_USER_TIME_RECORDS_SUCCESS,
    GET_USER_TIME_RECORDS_FAILURE,
    GET_NEXT_PAGE_USER_TIME_RECORDS_REQUEST,
} from "../const"
    
const initialState = {
    timeRecords: [],
    total: 0,
    tableLoading: false,
}

const handlers = {
    [GET_USER_TIME_RECORDS_REQUEST]: (state) => ({...state, tableLoading: true, timeRecords: [], total: 0}),
    [GET_USER_TIME_RECORDS_SUCCESS]: (state, {payload: {timeRecords}}) => ({
        ...state,
        timeRecords: [...state.timeRecords, ...timeRecords.items],
        total: timeRecords.total,
        tableLoading: false,
    }),
    [GET_USER_TIME_RECORDS_FAILURE]: (state) => ({...state, tableLoading: false}),

    [GET_NEXT_PAGE_USER_TIME_RECORDS_REQUEST]: (state) => ({...state}),

    DEFAULT: (state) => state,
}

const userTimeRecordsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default userTimeRecordsReducer
