import {
    GET_TIME_DETAILS_REQUEST,
    GET_TIME_DETAILS_SUCCESS,
    GET_TIME_DETAILS_FAILURE
} from "../const"
    
const initialState = {
    day: {
        current:"00:00",
        total:"00:00"
    },
    week:{
        current:"00:00",
        total:"00:00"
    },
    month:{
        current:"00:00",
        total:"00:00"
    },
    overtimes:"00:00",
    time_off_left: 0,
    unpaid_day_off: 0,
    last_time_off_request:{
        id: null,
        start_date: null,
        end_date: null,
        note: null,
        approved: null,
        user:{
            first_name: "",
            last_name: ""
        }
    },
    loading: false,
}
  
const handlers = {
    [GET_TIME_DETAILS_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_TIME_DETAILS_SUCCESS]: (state, { payload: { timeDetails } }) => ({
        ...state,
        ...timeDetails,
        loading: false,
    }),
    [GET_TIME_DETAILS_FAILURE]: (state) => ({ ...state, loading: false }),
    DEFAULT: (state) => state,
}

const timeTrackerDetailsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default timeTrackerDetailsReducer
  

