export const GET_ME_REQUEST = "GET_ME_REQUEST"
export const GET_ME_SUCCESS = "GET_ME_SUCCESS"
export const GET_ME_FAILURE = "GET_ME_FAILURE"

export const GET_CURRENT_USER_REQUEST = "GET_CURRENT_USER_REQUEST"
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS"
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE"

export const GET_USER_EQUIPMENT_REQUEST = "GET_USER_EQUIPMENT_REQUEST"
export const GET_USER_EQUIPMENT_SUCCESS = "GET_USER_EQUIPMENT_SUCCESS"
export const GET_USER_EQUIPMENT_FAILURE = "GET_USER_EQUIPMENT_FAILURE"

export const GET_USER_ONBOARDING_REQUEST = "GET_USER_ONBOARDING_REQUEST"

export const GET_USER_ONBOARDING_SUCCESS = "GET_USER_ONBOARDING_SUCCESS"

export const GET_USER_ONBOARDING_FAILURE = "GET_USER_ONBOARDING_FAILURE"

export const UPDATE_ONBOARDING_REQUEST = "UPDATE_ONBOARDING_REQUEST"

export const UPDATE_ONBOARDING_REQUEST_SUCCESS = "UPDATE_ONBOARDING_REQUEST_SUCCESS"

export const UPDATE_ONBOARDING_REQUEST_FAILURE = "UPDATE_ONBOARDING_REQUEST_FAILURE"


export const GET_TIME_TRACKER_CHART_REQUEST = "GET_TIME_TRACKER_CHART_REQUEST"
export const GET_TIME_TRACKER_CHART_SUCCESS = "GET_TIME_TRACKER_CHART_SUCCESS"
export const GET_TIME_TRACKER_CHART_FAILURE = "GET_TIME_TRACKER_CHART_FAILURE"

export const GET_USER_CONFIGS_REQUEST = "GET_USER_CONFIGS_REQUEST"
export const GET_USER_CONFIGS_SUCCESS = "GET_USER_CONFIGS_SUCCESS"
export const GET_USER_CONFIGS_FAILURE = "GET_USER_CONFIGS_FAILURE"

export const GET_USER_PROJECTS = "GET_USER_PROJECTS"

export const GET_USER_ALL_PROJECTS = "GET_USER_ALL_PROJECTS"

export const GENERATE_RANDOM_SMILE = "GENERATE_RANDOM_SMILE"

export const SET_ONBOARDING_ANIMATION = "SET_ONBOARDING_ANIMATION"

export const CHECK_FIRST_ONBOARDING_STEP = "CHECK_FIRST_ONBOARDING_STEP"

export const GET_USER_NEWCOINS = "GET_USER_NEWCOINS"

export const GET_USER_NEWCOINS_SPENT = "GET_USER_NEWCOINS_SPENT"


