import {
    GET_REPORTS_DATA_FAILURE,
    GET_REPORTS_DATA_REQUEST,
    GET_REPORTS_DATA_SUCCESS,
    GET_REPORTS_TABLE_FAILURE,
    GET_REPORTS_TABLE_REQUEST,
    GET_REPORTS_TABLE_SUCCESS,
    GET_USERS_REPORTS,
    SET_SELECTED_USER
} from "../const"

const initialState = {
    users: [],
    earn: [],
    overtimes: [],
    bonuses: [],
    currency: {
        id: 0,    
        iso_symbol: "",
        iso_code: ""
    },
    tableData: [
        {
            percentage: 0,
            total_duration: "",
            group: ""
        }
    ],
    hours_report: {
        current: "",
        total: ""
    },
    chart_data: {
        type: "year",
        data: [
            {
                date: "2021-01-01",
                total: 0
            },
        ]
    },
    selectedUser: null,
    working_days: 0,
    tableLoading: false,
    loading: false
}

const handlers = {
    [GET_REPORTS_TABLE_REQUEST]: (state) => ({ ...state, tableLoading: true }),
    [GET_REPORTS_TABLE_SUCCESS]: (state, { payload: { tableData } }) => ({
        ...state,
        tableData,
        tableLoading: false,
    }),
    [GET_REPORTS_TABLE_FAILURE]: (state) => ({ ...state}),

    [GET_REPORTS_DATA_REQUEST]: (state) => ({ ...state, loading: true }),
    [GET_REPORTS_DATA_SUCCESS]: (state, { payload: { tableData, salaryData, overtimeData, bonusesData, chartData } }) => ({
        ...state,
        tableData,
        earn: salaryData,
        overtimes: overtimeData,
        bonuses: bonusesData,
        ...chartData,
        loading: false,
    }),
    [GET_REPORTS_DATA_FAILURE]: (state) => ({ ...state}),
    
    [GET_USERS_REPORTS]: (state, { payload: { users } }) => ({
        ...state,
        users: users.items || users,
    }),

    [SET_SELECTED_USER]: (state, {payload: {selectedUser}}) => ({
        ...state,
        selectedUser
    }),

    DEFAULT: (state) => state,
}

const reportsPageReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default reportsPageReducer
  

