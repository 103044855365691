import http from "../../http"

import {API_V1_URL} from "../../constants/other"


const EMPLOYEE_POSITIONS_URL = `${API_V1_URL}/user-positions/`


export const getEmployeePositions = (params) => http.get(EMPLOYEE_POSITIONS_URL, {params})

export const createEmployeePosition = (payload) => http.post(EMPLOYEE_POSITIONS_URL, {data: payload})

export const updateEmployeePosition = (positionId, data) => http.put(`${EMPLOYEE_POSITIONS_URL}${positionId}`, {data: data})

export const deleteEmployeePosition = (positionId) => http.delete(`${EMPLOYEE_POSITIONS_URL}${positionId}`)
