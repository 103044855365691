import {getAllProjects} from "../api"
import {
    GET_NEXT_PAGE_PROJECTS_REQUEST,
    GET_PROJECTS_FAILURE,
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
} from "../const"


const request = () => ({type: GET_NEXT_PAGE_PROJECTS_REQUEST})

const success = (projects) => ({
    type: GET_PROJECTS_SUCCESS,
    payload: {projects},
})
const failure = () => ({type: GET_PROJECTS_FAILURE})

const searchRequest = () => ({type: GET_PROJECTS_REQUEST})


export const receiveNextPageProjects = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveProjectsSData} = await getAllProjects(params)

        dispatch(success(receiveProjectsSData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveProjects = (params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveProjectsSData} = await getAllProjects(params)
        dispatch(success(receiveProjectsSData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
