import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import TimeOffStatusFilter from "./components/TimeOffStatusFilter"

import {
    receiveNextPageUserTimeOffRequests,
    receiveNextPageTimeOffRequests,
    receiveUserTimeOffRequests,
    receiveTimeOffRequests
} from "./actions/timeOffRequestsActions"

import Protected from "../../components/Protected/Protected"
import TimeOffRequestsTable from "./components/TimeOffRequestsTable"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"

import {PAGE_SIZE, STATUS_FILTERS} from "../../constants/other"
import {useCustomHistory} from "../../hooks/useCustomHistory"
import {PERMISSIONS} from "../../constants/permissions"
import {generateCancelToken} from "../../http"


const TimeOffRequests = () => {
    const history = useCustomHistory()

    const dispatch = useDispatch()

    const auth = useSelector((state) => state.auth)
    const {
        tableLoading,
        timeOffRequests,
        total,
    } = useSelector((state) => state.timeOffRequests)
    const {hasPermission} = useSelector((state) => state.profile)

    const [searchKey, setSearchKey] = useState(history.location.state?.username ? history.location.state.username : null)
    const [userMessage, setUserMessage] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [loadCurrentUserRequests, setLoadCurrentUserRequests] = useState(!history.location.state?.username)
    const [statusFilter, setStatusFilter] = useState(STATUS_FILTERS.ALL)
    const [cancelToken, setCancelToken] = useState(null)

    const getTimeOffRequests = (page) => {
        page = page ? page : currentPage
        const params = {
            page: page,
            size: PAGE_SIZE,
        }
        const paramsExt = {
            ...params,
            username: searchKey,
            ...(statusFilter !== STATUS_FILTERS.ALL && { status: statusFilter })
        }

        dispatch(hasPermission(PERMISSIONS.READ_TIME_OFF_REQUEST)
            ? receiveTimeOffRequests(paramsExt, generateCancelToken(cancelToken, setCancelToken))
            : receiveUserTimeOffRequests(auth.user.uid, params)
        )
        setCurrentPage(page + 1)
    }

    const getNextTimeOffRequests = () => {
        const params = {
            page: currentPage,
            size: PAGE_SIZE,
        }
        const paramsExt = {
            ...params,
            username: searchKey,
            ...(statusFilter !== STATUS_FILTERS.ALL && { status: statusFilter })
        }

        dispatch(hasPermission(PERMISSIONS.READ_TIME_OFF_REQUEST)
            ? receiveNextPageTimeOffRequests(paramsExt)
            : receiveNextPageUserTimeOffRequests(auth.user.uid, params)
        )
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getTimeOffRequests(1)
        if (!loadCurrentUserRequests) {
            setLoadCurrentUserRequests(true)
        }
    }, [statusFilter])

    useEffect(() => {
        if (searchKey !== null && loadCurrentUserRequests) {
            const delayDebounceFn = setTimeout(() => {
                getTimeOffRequests(1)
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])
    
    return (
        <>
            <Helmet>
                <title>Time Off requests - Newsoft Inside</title>
            </Helmet>
            <div className="time-off-requests">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>
                    }
                </div>
                <div className={`time-off-requests-header${!hasPermission(PERMISSIONS.READ_TIME_OFF_REQUEST) ? "-user" : ""}`}>
                    <Protected permissions={[PERMISSIONS.READ_TIME_OFF_REQUEST]}>
                        <SearchForm value={searchKey}
                            onClick={() => setSearchKey("")}
                            onChange={(e) => setSearchKey(e.target.value)}
                        />
                    </Protected>
                    <TimeOffStatusFilter
                        setStatusFilter={setStatusFilter}
                    />
                </div>

                <InfiniteScroll
                    pageStart={0}
                    next={getNextTimeOffRequests}
                    hasMore={!tableLoading && timeOffRequests.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={timeOffRequests.length}
                    useWindow={false}
                >
                    {tableLoading ? <StyledCircularProgress/> :
                        <div className="time-off-requests-content">
                            <TimeOffRequestsTable
                                statusFilter={statusFilter}
                                timeOffRequests={timeOffRequests}
                                refreshTable={() => getTimeOffRequests(1)}
                                setUserMessage={setUserMessage}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default TimeOffRequests
