import {getCalendarDetails} from "../api"
import {GET_CALENDAR_DATA_FAILURE, GET_CALENDAR_DATA_REQUEST, GET_CALENDAR_DATA_SUCCESS} from "../const"
import {TIME_OFF_PRIORITIES} from "../../../constants/other"

const request = () => ({ type: GET_CALENDAR_DATA_REQUEST })

const success = (timeOffs, loggedDays, inReviewDays, params) => ({
    type: GET_CALENDAR_DATA_SUCCESS,
    payload: { timeOffs, loggedDays, inReviewDays, params },
})

const failure = () => ({ type: GET_CALENDAR_DATA_FAILURE })

export const receiveCalendarData = (userId, params) => async (dispatch) => {
    dispatch(request())
    try {
        const { data: {time_offs, logged_days, in_review_days} } = await getCalendarDetails(userId, params)

        const updateMapWithHighestPriority = (map, items) => {
            items.forEach(item => {
                const { date, type, created_at, ...rest } = item
                if (!map.has(date)) {
                    map.set(date, { type, created_at, ...rest })
                } else {
                    const existing = map.get(date)
                    if (TIME_OFF_PRIORITIES[type] > TIME_OFF_PRIORITIES[existing.type] ||
                        (TIME_OFF_PRIORITIES[type] === TIME_OFF_PRIORITIES[existing.type] && new Date(created_at) > new Date(existing.created_at))) {
                        map.set(date, { type, created_at, ...rest })
                    }
                }
            })
        }

        const timeOffsMap = new Map()
        const loggedDaysMap = new Map(logged_days.map(item => [item.date, item]))
        const inReviewDaysMap = new Map(in_review_days.map(item => [item.date, item]))

        updateMapWithHighestPriority(timeOffsMap, time_offs)

        dispatch(success(timeOffsMap, loggedDaysMap, inReviewDaysMap, params))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}