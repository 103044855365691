import {
    GET_TIME_OFF_USAGE_INFO_SUCCESS,
    GET_NEXT_PAGE_TIME_OFF_BALANCES_REQUEST,
    GET_TIME_OFF_BALANCES_FAILURE,
    GET_TIME_OFF_BALANCES_REQUEST,
    GET_TIME_OFF_BALANCES_SUCCESS,
    GET_TIME_OFF_USAGE_INFO_REQUEST,
    GET_TIME_OFF_USAGE_INFO_FAILURE,
    GET_TIME_OFF_BALANCES_UNIQUE_YEARS_REQUEST,
    GET_TIME_OFF_BALANCES_UNIQUE_YEARS_SUCCESS,
    GET_TIME_OFF_BALANCES_UNIQUE_YEARS_FAILURE,
} from "../const"


const defaultYears = [{year: new Date().getFullYear()}, ]

const initialState = {
    timeOffBalances: [],
    total: 0,
    tableLoading: true,
    loading: true,
    timeOffsUsageInfo: [],
    popupLoading: false,
    uniqueYears: defaultYears,
}

const handlers = {
    [GET_NEXT_PAGE_TIME_OFF_BALANCES_REQUEST]: (state) => ({...state, tableLoading: true}),
    [GET_TIME_OFF_BALANCES_SUCCESS]: (state, {payload: {timeOffBalances}}) => ({
        ...state,
        total: timeOffBalances.total,
        timeOffBalances: [...state.timeOffBalances, ...timeOffBalances.items],
        tableLoading: false,
        loading: false,
    }),
    [GET_TIME_OFF_BALANCES_FAILURE]: (state) => ({...state}),
    [GET_TIME_OFF_BALANCES_REQUEST]: (state) => ({...state, loading: true, timeOffBalances: [], total: 0}),
    
    [GET_TIME_OFF_USAGE_INFO_SUCCESS]: (state, {payload: {timeOffsUsageInfo}}) => ({...state, timeOffsUsageInfo, popupLoading: false}),
    [GET_TIME_OFF_USAGE_INFO_REQUEST]: (state) => ({...state, popupLoading: true}),
    [GET_TIME_OFF_USAGE_INFO_FAILURE]: (state) => ({...state, popupLoading: false}),

    [GET_TIME_OFF_BALANCES_UNIQUE_YEARS_REQUEST]: (state) => ({...state, uniqueYears: defaultYears}),
    [GET_TIME_OFF_BALANCES_UNIQUE_YEARS_SUCCESS]: (state, {payload: {uniqueYears}}) => ({
        ...state,
        uniqueYears,
    }),
    [GET_TIME_OFF_BALANCES_UNIQUE_YEARS_FAILURE]: (state) => ({...state}),

    DEFAULT: (state) => state,
}


const timeOffBalancesReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}

export default timeOffBalancesReducer
