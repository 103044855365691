import React, {useContext, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useCalendar} from "../../../hooks"
import Popup from "reactjs-popup"
import moment from "moment"

import {
    createTimeRecord,
    deleteTimeRecordTemplate,
    getUserProjects,
    duplicateTimeRecords,
    getUserTimeRecords
} from "../api"
import {updateTimeRecordById} from "../../TimeRecords/api"
import {receiveMyInfo} from "../../Profile/actions/userActions"

import AgreePopup from "../../../components/AgreePopup"

import {
    changeDateFormat,
    formatDate,
    getDaysTakenFromTimeOffBalanceMessage,
    getPreviousWorkingDay,
    isDateInFuture, minutesToHours,
    minutesToString,
    stringToMinutes
} from "../../../utils/datetime.utils"
import {
    getAuthorizedUserData, getPinTutorialSeen, getSelectedHoursType,
    getUserViewedAs, savePinTutorialSeen, saveSelectedHoursType,
} from "../../../utils/localstorage.utils"
import {canEditTimeRecord} from "../../../utils/permission.utils"

import DDList from "../../../toolkits/DDList/DDList"
import DurationPicker from "../../../toolkits/DurationPicker/DurationPicker"
import Textarea from "../../../toolkits/Textarea/Textarea"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import Calendars from "../../../toolkits/Calendar/Calendar"

import {
    DD_MM_YYYY, DESKTOP_WIDTH,
    ERROR_CODE,
    HOURS_TYPE, HOURS_TYPE_ONLY_TIME_OFF_OPTIONS,
    HOURS_TYPE_TIME_TRACKER_OPTIONS,
    MOBILE_WIDTH,
    REQUEST_OVERTIME_COEFFICIENT_ERROR,
    SUCCESS_CODE,
    TIME_RECORD_STATUS,
    TIME_TRACKER_SUBMIT_ERROR, WORKING_HOURS_STRING,
} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import ToggleOffIcon from "@mui/icons-material/ToggleOff"
import {RangeDatePicker} from "../../../toolkits/Calendar/RangeDatePicker"
import {createTimeOffRequest} from "../../TimeOffRequests/api"
import {useMediaQuery} from "react-responsive"
import SingleDatePicker from "../../../toolkits/Calendar/SingleDatePicker"
import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import CancelRoundedIcon from "@mui/icons-material/CancelRounded"
import {receiveUserTimeRecordTemplates} from "../actions/timeRecordTemplatesActions"
import {mapTimeOffTypeToTitle} from "../../../utils/timeoff.utils"
import { ThemeContext } from "../../../styles/theme-context"
import PinTutorialPopup from "./PinTutorialPopup"


const TimeRecordForm = ({
    timeRecord,
    editFlag,
    updateTimeTrackerForDay,
    refreshData,
    setUserMessage,
    close,
    userId,
    setRequestOvertimeCoefficient,
    setDateForOvertimeRequest,
    timeOffTabActive,
    setTimeOffTabActive,
    previousWorkingDay,
    setPreviousWorkingDay,
    hasPreviousDayRecords,
    setHasPreviousDayRecords,
    setSelectedDate
}) => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)
    const currentDayRecords = useSelector((state) => state.timeRecords.items)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const isTablet = useMediaQuery({maxWidth: DESKTOP_WIDTH})
    const [isLoading, setIsLoading] = useState(false)
    const {accessToTimeTracker} = getAuthorizedUserData()

    const [project, setProject] = useState(timeRecord?.project?.id !== null ? timeRecord?.project : "")
    const savedHoursType = getSelectedHoursType()
    const [hoursType, setHoursType] = useState(
        savedHoursType || HOURS_TYPE.OfficeWork)
    const [duration, setDuration] = useState(timeRecord ? minutesToString(timeRecord.duration) : "00:00")
    const [note, setNote] = useState(timeRecord ? timeRecord.note : "")
    const [date, setDate] = useState(timeRecord ? new Date(timeRecord.date) : new Date())
    const [dates, setDates] = useState(null)
    const [overtime, setOvertime] = useState(editFlag && timeRecord?.hours_type === HOURS_TYPE.Overtime)

    const [projects, setProjects] = useState(null)
    const [publicDayOff, setPublicDayOff] = useState(null)
    const [approvedTimeOff, setApprovedTimeOff] = useState(null)

    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [requestTimeOffDisabled, setRequestTimeOffDisabled] = useState(false)
    const [reasonSubmitDisabled, setReasonDisabledSubmit] = useState(null)
    const [requestChangesAvailable, setRequestChangesAvailable] = useState(null)
    const [requestChangesPopup, setRequestChangesPopup] = useState(false)
    const [selectedBtn, setSelectedBtn] = useState(savedHoursType || HOURS_TYPE_TIME_TRACKER_OPTIONS[0].value)
    const [showTimeOffUsedLabel, setShowTimeOffUsedLabel] = useState(false)
    const [timeOffUsed, setTimeOffUsed] = useState(0)
    const timeOffTemplates = useSelector((state) => state.timeRecordTemplates.items)
    const [loadingPreviousRecords, setLoadingPreviousRecords] = useState(false)
    const [showPinTutorialPopup, setShowPinTutorialPopup] = useState(false)
    const theme = useContext(ThemeContext)

    const {isPublicDayOff, isTimeOff, getApprovedTimeOff} = useCalendar()

    const resetInputData = () => {
        setHoursType(selectedBtn)
        setProject(null)
        setDuration("00:00")
        setNote("")
        setDates(null)
        setOvertime(false)
    }

    const isDisabledDate = (newDate) => {
        newDate = changeDateFormat(newDate, DD_MM_YYYY, "YYYY-MM-DD")

        const isCanEdit = canEditTimeRecord(newDate, accessToTimeTracker)

        if (isDateInFuture(date)){
            const reason = TIME_TRACKER_SUBMIT_ERROR.workInFuture
            setReasonDisabledSubmit(reason)
            return true
        }

        if (!isCanEdit) {
            if (!hasPermission(PERMISSIONS.WRITE_TIME_RECORD)) {
                const reason = editFlag
                    ? TIME_TRACKER_SUBMIT_ERROR.recordInPast.replace("{accessToTimeTracker}", accessToTimeTracker.value)
                    : TIME_TRACKER_SUBMIT_ERROR.recordInPastExt.replace("{accessToTimeTracker}", accessToTimeTracker.value)
                setReasonDisabledSubmit(reason)
            } else {
                setSubmitDisabled(false)
                setReasonDisabledSubmit(null)
            }
            setRequestChangesAvailable(true)
            return !!editFlag
        } else {
            setRequestChangesAvailable(false)
        }

        if (editFlag) {
            if (isDateInFuture(newDate)) {
                const reason = TIME_TRACKER_SUBMIT_ERROR.workInFuture
                setReasonDisabledSubmit(reason)
                return true
            }
        }
        setReasonDisabledSubmit(null)
        return false
    }

    const onDateRangeChange = (selectedDates) => {
        let startDate = selectedDates[0]?.toDate()
        let endDate = selectedDates.length === 2 ? selectedDates[1]?.toDate() : startDate

        let totalDays = 0
        for (let m = moment(startDate); m.diff(endDate, "days") <= 0; m.add(1, "days")) {
            const dayOfWeek = m.isoWeekday()
            const formattedDate = m.format("YYYY-MM-DD")

            const isWeekend = dayOfWeek === 6 || dayOfWeek === 7
            const isHoliday = isPublicDayOff(formattedDate)
            const isTimeOffDate = isTimeOff(formattedDate)

            if (!isWeekend && !isHoliday && !isTimeOffDate) {
                totalDays++
            }
            setTimeOffUsed(totalDays)
        }

        if (!endDate || startDate.getTime() === endDate.getTime()) {
            setDates([selectedDates[0]])
        } else if (endDate && startDate > endDate) {
            setDates([selectedDates[1]])
        } else {
            setDates(selectedDates)
        }
    }

    const onDateChange = (newDate) => {
        newDate = newDate.toDate()
        setDate(newDate)
        if (!editFlag) {
            setSelectedDate(newDate)
            updateTimeTrackerForDay(newDate)
        }
    }

    const handleChangeHoursType = (newHoursType) => {
        setHoursType(newHoursType)
        setSelectedBtn(newHoursType)

        if (!newHoursType) {
            setDate(new Date())
            setRequestTimeOffDisabled(true)
            setTimeOffTabActive(true)
            setShowTimeOffUsedLabel(false)
        } else {
            setDates(null)
            if (!editFlag) {
                setTimeOffTabActive(false)
            }
            saveSelectedHoursType(newHoursType)
        }
    }

    const handleDurationInput = (e) => {
        if (
            !e.target.selectionStart && Number(e.nativeEvent.data) > 2 ||
            e.target.selectionStart === 1 && Number(e.nativeEvent.data) > 3
        ) {
            e.target.value = "0" + e.nativeEvent.data + e.target.value.slice(2)
        }
        setDuration(e.target.value)
    }

    const handleSubmit = ({close}) => {
        if (close) close()
        const data = {
            user_id: getUserViewedAs() ? getUserViewedAs().user_id : auth.user.uid,
            project_id: project ? project.id : null,
            hours_type: hoursType,
            date: formatDate(date),
            duration: stringToMinutes(duration),
            note: note,
            status: requestChangesAvailable
                ? (
                    !hasPermission(PERMISSIONS.WRITE_TIME_RECORD)
                        ? TIME_RECORD_STATUS.IN_REVIEW
                        : TIME_RECORD_STATUS.APPROVED
                )
                : TIME_RECORD_STATUS.LOGGED
        }
        if (!hoursType) {
            setUserMessage({message: "You must select hours type", code: ERROR_CODE})
            return
        }
        if (!project) {
            setUserMessage({message: "You must select a project", code: ERROR_CODE})
            return
        }
        if (duration === "00:00") {
            setUserMessage({message: "You can't track time record with 00:00 time"})
            return
        }
        setSubmitDisabled(true)
        createTimeRecord(data)
            .then(() => {
                setSubmitDisabled(false)

                refreshData(date)
                resetInputData()
                setUserMessage({
                    message: `Your time record ${requestChangesAvailable ? "request" : ""} was successfully created!`,
                    code: SUCCESS_CODE
                })
                if (close) close()

                dispatch(receiveMyInfo())

                if (!(getPinTutorialSeen() === "true") && !isMobile) {
                    setShowPinTutorialPopup(true)
                }
            })
            .catch(error => {
                if (close) close()
                if (error.response.data.message == REQUEST_OVERTIME_COEFFICIENT_ERROR) {
                    setDateForOvertimeRequest(date)
                    setRequestOvertimeCoefficient(true)
                    resetInputData()
                } else {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                }
            })
    }

    const handleEditSubmit = (e) => {
        e.preventDefault()

        const data = {
            project_id: project ? project.id : null,
            hours_type: hoursType,
            duration: stringToMinutes(duration),
            date: formatDate(date),
            note: note
        }
        if (duration === "00:00") {
            setUserMessage({message: "You can't track time record with 00:00 time"})
            return
        }


        updateTimeRecordById(timeRecord.id, data)
            .then(() => {
                refreshData(date)
                setUserMessage({message: "Your time record was successfully updated!", code: SUCCESS_CODE})

                // update profile data
                dispatch(receiveMyInfo())
            })
            .catch(error => {
                if (error.response.data.message == REQUEST_OVERTIME_COEFFICIENT_ERROR) {
                    setDateForOvertimeRequest(date)
                    setRequestOvertimeCoefficient(true) 
                    close()
                    resetInputData()
                } else {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                }
            })
    }

    const handleRequestTimeOff = (e) => {
        e.preventDefault()

        const data = {
            start_date: formatDate(dates[0].toDate()),
            end_date: dates.length !== 2 ? formatDate(dates[0].toDate()) : formatDate(dates[1].toDate()),
            note: note,
            type: hoursType,
            user_id: auth.user.uid,
        }
        setIsLoading(true)
        createTimeOffRequest(data)
            .then(() => {
                setIsLoading(false)
                refreshData(dates[0]?.toDate())
                resetInputData()
                dispatch(receiveMyInfo())
                setUserMessage({message: "Your Time Off Request was successfully sent!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setIsLoading(false)
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleDeleteTimeRecordTemplate = (id) => {
        deleteTimeRecordTemplate(id)
            .then(() => {
                setUserMessage({message: "Time record template was successfully deleted!", code: SUCCESS_CODE})
                dispatch(receiveUserTimeRecordTemplates(userId))
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const handleTemplateClick = (template) => {
        setProject(template.project)
        setDuration(minutesToString(template.duration))
    }

    const checkPreviousDayRecords = () => {
        setLoadingPreviousRecords(true)

        getUserTimeRecords(userId, { created_at: formatDate(previousWorkingDay), hours_type_in: WORKING_HOURS_STRING, status_in: [TIME_RECORD_STATUS.LOGGED, TIME_RECORD_STATUS.APPROVED].toString()})
            .then(response => {
                const records = response.data.items
                setHasPreviousDayRecords(records && records.length > 0)
            })
            .catch(error => {
                console.error("Error fetching time records:", error)
                setHasPreviousDayRecords(false)
            })
            .finally(() => {
                setLoadingPreviousRecords(false)
            })
    }

    const handleDuplicateTimeRecords = () => {
        const data = {
            target_date: formatDate(date),
            user_id: userId || auth.user.uid
        }
        duplicateTimeRecords(data)
            .then(() => {
                refreshData(date)
                setUserMessage({message: `Your Time Records from ${formatDate(previousWorkingDay, "DD MMM")} were successfully duplicated!`, code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    useEffect(() => {
        if (userId || auth.user)
        {
            dispatch(receiveUserTimeRecordTemplates(userId || auth.user.uid))
            getUserProjects(userId || auth.user.uid, false)
                .then((response) => {
                    setProjects(response.data.map((userProject) => userProject.project))
                })
                .catch(error => {
                    console.log(error)
                })
            if (!editFlag) {
                resetInputData()
            }
        }
    }, [editFlag, auth.user?.uid])

    useEffect(() => {
        setShowTimeOffUsedLabel(hoursType && hoursType != HOURS_TYPE.UnpaidDayOff && dates?.length > 0)
        setRequestTimeOffDisabled(!dates || dates.length === 0 || !hoursType)
    }, [dates, hoursType])

    useEffect(() => {
        const evaluateDate = () => {
            return isDisabledDate(date) || isDateInFuture(date)
        }

        const projectSelected = project !== null && project !== ""
        const durationValid = duration !== "00:00"
        const dateValid = !evaluateDate()

        const shouldDisableSubmit = !(projectSelected && durationValid && dateValid)

        setSubmitDisabled(shouldDisableSubmit)
    }, [project, duration, date])

    useEffect(() => {
        setHoursType(overtime ? HOURS_TYPE.Overtime : selectedBtn)
    }, [overtime])

    useEffect(() => {
        if (!editFlag){
            const calculatedPreviousWorkDay = getPreviousWorkingDay(date)
            setPreviousWorkingDay(calculatedPreviousWorkDay)}

        const publicDayOff = isPublicDayOff(date)
        setPublicDayOff(publicDayOff)
        const approvedTimeOff = getApprovedTimeOff(date)
        setApprovedTimeOff(approvedTimeOff)
    }, [date, hoursType])

    useEffect(() => {
        if (previousWorkingDay) {
            checkPreviousDayRecords()
        }
    }, [previousWorkingDay, userId])

    const InputsCard = (
        <div className="time-tracker-content-form-inputs">
            <div className="time-tracker-content-form-hours-chips">
                {HOURS_TYPE_TIME_TRACKER_OPTIONS
                    .filter((op, index) => !editFlag || index < 2)
                    .map((op) => (
                        <button key={op.value} className={`${selectedBtn == op.value ? "time-tracker-content-form-hours-chips-selected t-s3" : `t-s4 ${theme.chipsClassName}`} ${editFlag ? "without-time-off" : ""}`}
                            value={op.value} onClick={() => handleChangeHoursType(op.value)}>
                            {op.label}
                        </button>))
                }
            </div>

            {timeOffTabActive && (
                <DDList
                    text_label="Time Off Type"
                    placeholder="Select time off type"
                    required={true}
                    onChange={(option) => setHoursType(option.value)}
                    listOptions={HOURS_TYPE_ONLY_TIME_OFF_OPTIONS}
                    value={hoursType && {value: hoursType, label: hoursType}}
                />
            )}

            {isMobile &&
                (timeOffTabActive ?
                    <RangeDatePicker
                        label="Date"
                        isMobile={true}
                        value={dates}
                        footerOnBottom={true}
                        onChange={onDateRangeChange}
                        setUserMessage={setUserMessage}
                    />
                    :
                    <SingleDatePicker
                        label="Date"
                        className="single-datepicker-field"
                        defaultValue={date ? date : new Date()}
                        disableDays={false}
                        setNewDate={setDate}
                        showTimeOffsAndLoggedDays={true}
                        onChange={onDateChange}
                    />
                )
            }

            {!timeOffTabActive && (
                <>
                    <DDList
                        text_label="Project"
                        placeholder="Select project"
                        onChange={(project) => setProject(project.value)}
                        listOptions={projects && projects.map((project) => ({
                            value: project,
                            label: `${project.name}`
                        }))}
                        value={project && {value: project, label: `${project.name}`}}
                    />

                    <div className="time-tracker-content-form-inputs-duration">
                        <DurationPicker
                            text_label="Duration"
                            value={duration}
                            onChange={handleDurationInput}
                        />
                        <div className="time-tracker-content-form-inputs-duration-slider" onClick={() => {setOvertime(!overtime)}}>
                            <span className={`t-b3 slider-label ${editFlag ? "label-alternative-position" : ""}`}>Overtime</span>
                            {overtime ? <ToggleOnIcon className={`slider ${editFlag ? "slider-alternative-position" : ""}`}/> : <ToggleOffIcon className={`slider-inactive ${editFlag ? "slider-alternative-position" : ""}`}/>}
                        </div>
                    </div>
                </>
            )}
            <div>
                <Textarea
                    text_label="Note"
                    value={note}
                    placeholder="Write down some note..."
                    onChange={e => setNote(e.target.value)}
                />
            </div>

            {!editFlag ? <>
                {timeOffTemplates && !timeOffTabActive &&
                    <div className="time-tracker-content-form-inputs-template">
                        {timeOffTemplates.map((template) => (
                            <div key={template.id} onClick={() => handleTemplateClick(template)} className="time-tracker-content-form-inputs-template-bubble t-b2">
                                <span className="time-tracker-content-form-inputs-template-bubble-project">{template.project.name}</span>, {Math.floor(minutesToHours(template.duration)) > 0 ? `${Math.floor(minutesToHours(template.duration))} h ` : ""}
                                {template.duration % 60 > 0 ? `${template.duration % 60} m` : ""}
                                <CancelRoundedIcon
                                    onClick={(e) => {e.stopPropagation()
                                        handleDeleteTimeRecordTemplate(template.id)}}
                                    className="time-tracker-content-form-inputs-template-remove"
                                />
                            </div>

                        ))}
                    </div>
                }
                <div className={`time-tracker-content-form-inputs-button${timeOffTabActive ? "-bottom" : ""}`}>
                    {timeOffTabActive ?
                        <>
                            {showTimeOffUsedLabel && !isMobile &&
                            <span className={`t-s4 ${isLoading ? "time-tracker-content-form-inputs-loading" : "time-tracker-content-form-inputs-time-off-label" }`}>{isLoading ? <StyledCircularProgress/> : getDaysTakenFromTimeOffBalanceMessage(timeOffUsed)}</span>}

                            <SmallButton disabled={requestTimeOffDisabled} type={requestTimeOffDisabled ? "disabled" : ""}
                                onClick={handleRequestTimeOff}>SEND REQUEST</SmallButton>
                        </>
                        :(
                            requestChangesAvailable ?
                                <SmallButton
                                    onClick={() => {setRequestChangesPopup(true)}}
                                    disabled={submitDisabled}
                                    type={submitDisabled ? "disabled" : ""}
                                >{`${!hasPermission(PERMISSIONS.WRITE_TIME_RECORD) ? "SEND REQUEST" : "CREATE CHANGES"}`}</SmallButton> :
                                <SmallButton  disabled={submitDisabled} type={submitDisabled ? "disabled" : ""}
                                    onClick={handleSubmit}>SUBMIT</SmallButton>
                        )
                    }
                </div>
                {!timeOffTabActive && !currentDayRecords.length && !loadingPreviousRecords && hasPreviousDayRecords && !isDateInFuture(date) && canEditTimeRecord(date, accessToTimeTracker) && (
                    <div className="time-tracker-content-form-inputs-button">
                        <SmallButton onClick={handleDuplicateTimeRecords} btnType={"secondary"}>DUPLICATE TIME FROM {formatDate(previousWorkingDay, "ddd, DD MMM")}</SmallButton>
                    </div>
                )}

                {reasonSubmitDisabled && !timeOffTabActive &&
                    <div className="time-tracker-content-form-inputs-reason t-b1">
                        {reasonSubmitDisabled}
                    </div>
                }

                {requestChangesAvailable && !timeOffTabActive &&
                    <div className="time-tracker-content-form-inputs-reason t-b1">
                        
                    </div>
                }

                <Popup open={requestChangesPopup} closeOnDocumentClick onClose={() => setRequestChangesPopup(false)} modal>
                    {close => (
                        <AgreePopup
                            title={!hasPermission(PERMISSIONS.WRITE_TIME_RECORD)
                                ? "Do you want to send request time records changes to your manager?"
                                : "Do you want to create changes?"
                            }
                            buttonText="SEND"
                            onSave={() => handleSubmit({close})}
                            close={close}
                        />
                    )}
                </Popup>
            </> : <></>}
            {(publicDayOff || approvedTimeOff) && !timeOffTabActive && (isMobile || isTablet) ?
                <div className={`t-s1 public-holiday-div${editFlag ? "-edit" : ""}`}>
                    {publicDayOff ? `Public Holiday: ${publicDayOff.description}` : `Time Off: ${mapTimeOffTypeToTitle(approvedTimeOff.type)}`}
                </div> : <></>
            }
        </div>
    )

    const DatepickerCard = (
        <div className="time-tracker-content-calendar">
            <div className="time-tracker-content-calendar-picker">
                {!isMobile &&
                    (timeOffTabActive ?
                        <RangeDatePicker value={dates} calendarOnly onChange={onDateRangeChange} calendarTitle={"Time Off Date"} setUserMessage={setUserMessage}/>
                        :
                        <Calendars noToolbar={editFlag} onChange={onDateChange} day={date ? date : new Date()}/>)
                }
            </div>
        </div>
    )

    return (
        <>
            <div className={`time-tracker-content-form ${editFlag ? "shorter-form" : ""}`}>
                {DatepickerCard}
                {InputsCard}
            </div>
            {editFlag ?
                <>
                    <div className="popup-bottom">
                        <div className="popup-bottom-button-container">
                            <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                            {editFlag ?
                                <SmallButton
                                    disabled={submitDisabled && !hasPermission(PERMISSIONS.WRITE_TIME_RECORD)}
                                    type={(submitDisabled && !hasPermission(PERMISSIONS.WRITE_TIME_RECORD)) ? "disabled" : ""}
                                    onClick={handleEditSubmit}
                                >SAVE</SmallButton>
                                : <SmallButton onClick={handleEditSubmit}> SAVE </SmallButton>
                            }
                        </div>
                    </div>
                    {reasonSubmitDisabled &&
                        <div className="edit-time-record-popup-reason-disabled t-b1">
                            {reasonSubmitDisabled}
                        </div>
                    }
                </>
                : <></>
            }

            <Popup open={!isMobile && showPinTutorialPopup} closeOnDocumentClick={false} onClose={() => {setShowPinTutorialPopup(false); savePinTutorialSeen()}} modal>
                {close => (
                    <PinTutorialPopup
                        close={close}
                    />
                )}
            </Popup>
        </>
    )
}

export default TimeRecordForm
