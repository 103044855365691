import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const EQUIPMENT_URL = `${API_V1_URL}/equipment/`


export const getEquipment = (params, cancelToken) => http.get(EQUIPMENT_URL, {params}, {}, cancelToken)

export const getEquipmentById = (equipment_id) => http.get(`${EQUIPMENT_URL}${equipment_id}`)

export const createEquipment = (payload) => http.post(EQUIPMENT_URL, {data: payload})

export const updateEquipmentById = ({equipment_id, data}) => http.put(`${EQUIPMENT_URL}${equipment_id}`, {data: data})

export const deleteEquipmentById = (equipment_id) => http.delete(`${EQUIPMENT_URL}${equipment_id}`)

export const assignEquipmentToUser = (payload) => http.post(`${EQUIPMENT_URL}assign`, {data: payload})

export const unassignEquipmentFromUser = (payload) => http.post(`${EQUIPMENT_URL}unassign`, {data: payload})

export const updateMultipleEquipment = (payload) => http.post(`${EQUIPMENT_URL}update-multiple`, {data: payload})
