import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveEquipment} from "../../../Equipment/actions/equipmentActions"
import {updateMultipleEquipment} from "../../../Equipment/api"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import DynamicBoxList from "../../../../toolkits/DynamicBoxList"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"

import {ERROR_CODE, SUCCESS_CODE} from "../../../../constants/other"


const EditEquipmentPopup = ({user, userEquipment, receiveUserEquipment, setUserMessage, close}) => {
    const dispatch = useDispatch()

    const [equipmentIds, setEquipmentIds] = useState([])

    const {equipment, tableLoading} = useSelector(state => state.equipment)


    const handleSubmit = () => {

        updateMultipleEquipment({
            user_id: user.id,
            equipment_ids: equipmentIds,
        })
            .then(() => {
                dispatch(receiveUserEquipment(user.id))
                close()
                setUserMessage({message: "Equipment was successfully assigned!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }


    useEffect(() => {
        dispatch(receiveEquipment({free: true}))
    }, [])

    return (
        <CustomPopup text_title="Change Equipment">
            <DynamicBoxList
                defaultValues={
                    userEquipment.reduce((result, category) => 
                        result.concat(category.equipment.map(x => x.id))
                    , [])
                }
                items={userEquipment.reduce((result, category) =>
                    result.concat(category.equipment)
                , []).concat(equipment)
                }
                loading={tableLoading}
                onChange={setEquipmentIds}
                searchFunc={(eq, searchKey) =>
                    eq.name.match(new RegExp(searchKey, "i")) ||
                    (eq.serial_number &&
                        eq.serial_number.match(new RegExp(searchKey, "i")))
                }
                getContent={eq => eq.name}
                getAdditionalInfo={eq => eq.serial_number}
            />

            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit}>SAVE</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EditEquipmentPopup
