import {
    GET_CLIENTS_REQUEST,
    GET_CLIENTS_SUCCESS,
    GET_CLIENTS_FAILURE,
    GET_NEXT_PAGE_CLIENTS_REQUEST,
} from "../const"


const initialState = {
    clients: [],
    total: 0,
    tableLoading: false,
}
  
const handlers = {
    [GET_CLIENTS_REQUEST]: (state) => ({...state, tableLoading: true, clients: [], total: 0}),
    [GET_CLIENTS_SUCCESS]: (state, {payload: {clients}}) => ({
        ...state,
        clients: [...state.clients, ...clients.items],
        total: clients.total,
        tableLoading: false,
    }),
    [GET_CLIENTS_FAILURE]: (state) => ({...state, tableLoading: false}),
    
    [GET_NEXT_PAGE_CLIENTS_REQUEST]: (state) => ({...state}),
    
    DEFAULT: (state) => state,
}

const clientsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default clientsReducer
