import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveEmployees} from "../../Employees/actions/employeesActions"
import {assignEquipmentToUser, unassignEquipmentFromUser} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import DDList from "../../../toolkits/DDList/DDList"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const AssignEquipmentPopup = ({equipment, assign, refreshTable, setUserMessage, close}) => {
    const dispatch = useDispatch()
    
    const users = useSelector((state) => state.employees.employees)

    const full_name = equipment.user && `${equipment.user.first_name} ${equipment.user.last_name}`
    const [user, setUser] = useState({})
    
    const handleSubmit = () => {
        if (assign) {
            assignEquipmentToUser({
                equipment_id: equipment.id,
                user_id: user.value,
            })
                .then(() => {
                    refreshTable()
                    setUserMessage({message: "Equipment was successfully assigned!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        } else {
            unassignEquipmentFromUser({
                equipment_id: equipment.id,
                user_id: equipment.user.id,
            })
                .then(() => {
                    refreshTable()
                    setUserMessage({message: "Equipment was successfully unassigned!", code: SUCCESS_CODE})
                })
                .catch(error => {
                    setUserMessage({message: error.response.data.message, code: ERROR_CODE})
                })
        }
    }

    useEffect(() => {
        dispatch(receiveEmployees())
    }, [])

    return (
        <CustomPopup
            className="assign-equipment-popup"
            wrapperClass="assign-equipment-popup-wrapper"
            isSubtitle={!assign}
            isAgreePopup={!assign}
            text_title={assign ?
                `Assign "${equipment.name}"` :
                `Do you want to unassign "${equipment.name}" from ${full_name}?`}
        >
            {assign &&
                <DDList
                    placeholder="" 
                    listOptions={users &&
                        users.map((user) => ({value: user.id, label: `${user.first_name} ${user.last_name}`}))}
                    onChange={setUser}
                />
            }
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit}>{assign ? "ASSIGN" : "UNASSIGN"}</SmallButton>
                </div>
            </div>
        </CustomPopup>
    )  
}

export default AssignEquipmentPopup
