import {
    GET_DAY_TIME_RECORDS_SUCCESS,
    GET_DAY_TIME_RECORDS_REQUEST,
    GET_DAY_TIME_RECORDS_FAILURE
} from "../const"

import {getDayTimeRecords} from "../api"

export const request = () => ({ type: GET_DAY_TIME_RECORDS_REQUEST })

const success = (dayTimeRecords) => ({
    type: GET_DAY_TIME_RECORDS_SUCCESS,
    payload: { dayTimeRecords },
})

const failure = () => ({ type: GET_DAY_TIME_RECORDS_FAILURE })


export const receiveDayTimeRecordsInfo = (user_id, params) => async (dispatch) => {
    dispatch(request())
    try {
        const { data: receiveDayTimeRecordsInfoData } = await getDayTimeRecords(user_id, params)
        dispatch(success(receiveDayTimeRecordsInfoData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}