import http from "../../http"
import {API_V1_URL} from "../../constants/other"

const REPORTS_URL = `${API_V1_URL}/time-records`
const USERS_URL = `${API_V1_URL}/users`
const USER_REPORT_URL = `${API_V1_URL}/user-report`

export const getReportsTable = (userId, params, cancelToken) =>
    http.get(`${REPORTS_URL}/grouped-reports/${userId}`, {params}, {}, cancelToken)

export const getReportsSalary = (userId, params) => http.get(`${USERS_URL}/${userId}/earn`, {params})

export const getReportsOvertime = (userId, params) => http.get(`${USERS_URL}/${userId}/user-overtimes`, {params})

export const getReportsBonuses = (userId, params) => http.get(`${USERS_URL}/${userId}/bonuses-report`, {params})

export const getReportsChart = (userId, params, cancelToken) =>
    http.get(`${REPORTS_URL}/time-tracker-chart/${userId}`, {params}, {}, cancelToken)

export const getSearchReports = (find, cancelToken) =>
    http.get(`${USERS_URL}/`, {params: {name: find}}, {}, cancelToken)

export const getTotalReports = (userId, params, cancelToken) =>
    http.get(`${USER_REPORT_URL}/${userId}`, {params}, {}, cancelToken)

export const  getSalaryEarn = (userId, params) => http.get(`${USER_REPORT_URL}/${userId}/earn-info`, {params})

export const  getOvertimeEarn = (userId, params) => http.get(`${USER_REPORT_URL}/${userId}/overtime-info`, {params})

export const  getBonusEarn = (userId, params) => http.get(`${USER_REPORT_URL}/${userId}/bonus-info`, {params})

export const getDayTimeRecords = (userId, params) =>
    http.get(`${USERS_URL}/${userId}/time-records`, {params})