import React from "react"
import {useSelector} from "react-redux"
import {useMediaQuery} from "react-responsive"

import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"

import Protected from "../../../components/Protected/Protected"

import {MOBILE_WIDTH} from "../../../constants/other"
import {PERMISSIONS} from "../../../constants/permissions"
import { getColorByHoursType } from "../../../utils/other.utils"

const ReportTable = ({groupBy, setGroupBy, handleReportsTable, redirectTimeRecords, isMyReports}) => {
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    
    const {
        tableLoading,
        tableData,
        loading,
    } = useSelector(state => state.reportsPage)

    const tableItems = tableData.map(item => (
        <tr key={item.group + Math.random()} className="reports-table-item">
            <td className="t-b2">
                {groupBy !== "project" ?
                    <li style={{color: getColorByHoursType(item.group)}}>
                        <span style={{color: "black"}}>{item.group}</span>
                    </li> :
                    <>{item.group}</>
                }
            </td>
            <td className="t-b2">{item.total_duration}</td>
            <td className="t-b2">{Math.round(item.percentage * 100)}%</td>
        </tr>
    ))

    return (
        <div className={isMobile ? "scrollable-table" : ""}>
            <div className="reports-table">
                <div>
                    <div className="reports-menu">
                        <span
                            onClick={() => {setGroupBy("project"); handleReportsTable("project")}}
                            className={groupBy === "project" ? "reports-menu-item  reports-menu-active t-s3" : "reports-menu-item t-s4"}
                        >
                    Project
                        </span>
                        <span
                            onClick={() => {setGroupBy("hours_type"); handleReportsTable("hours_type")}}
                            className={groupBy === "hours_type" ? "reports-menu-item  reports-menu-active t-s3" : "reports-menu-item t-s4"}
                        >
                    Hours Type
                        </span>
                    </div>
                    <Protected permissions={isMyReports ? [PERMISSIONS.READ_OWN_TIME_RECORD] : [PERMISSIONS.READ_TIME_RECORD]}>
                        <div className="reports-menu-all-records">
                            <span
                                className="t-s3"
                                onClick={redirectTimeRecords}
                            >all records</span>
                        </div>
                    </Protected>
                </div>
                {(tableLoading || loading) ? <StyledCircularProgress/> :
                    <table>
                        <thead>
                            <tr>
                                <th className="t-h3">{groupBy === "project" ? "project" : "hours type"}</th>
                                <th className="t-h3">duration</th>
                                <th className="t-h3">percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableItems}
                        </tbody>
                    </table>
                }
                {!tableData[0]?.group && !tableLoading && !loading &&
                    <div className="reports-table-empty t-h1">Empty Result</div>
                }
            </div>
        </div>
    )
}

export default ReportTable
