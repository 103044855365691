import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import {useDispatch, useSelector} from "react-redux"
import InfiniteScroll from "react-infinite-scroller"

import {generateCancelToken} from "../../http"

import {
    receiveTimeOffBalancesUniqueYears,
    receiveNextPageTimeOffBalances,
    receiveTimeOffBalances,
} from "./actions/timeOffBalancesActions"

import TimeOffBalanceTable from "./components/TimeOffBalanceTable"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import DDListYear from "../../toolkits/DDListYear"

import {PAGE_SIZE} from "../../constants/other"


const TimeOffBalance = () => {
    const dispatch = useDispatch()

    const {
        loading,
        tableLoading,
        timeOffBalances,
        total,
        uniqueYears,
    } = useSelector((state) => state.timeOffBalances)

    const [cancelToken, setCancelToken] = useState(null)

    const [currentPage, setCurrentPage] = useState(1)
    const [searchKey, setSearchKey] = useState(null)
    const [year, setYear] = useState(new Date().getFullYear())

    const getTimeOffBalances = (page) => {
        page = page ? page : currentPage
        const params = {
            size: PAGE_SIZE,
            page,
            year,
            username: searchKey,
        }

        dispatch(receiveTimeOffBalances({
            params,
            cancelToken: generateCancelToken(cancelToken, setCancelToken),
        }))
        setCurrentPage(page + 1)
    }

    const getNextTimeOffBalances = () => {
        const params = {
            size: PAGE_SIZE,
            page: currentPage,
            year,
            username: searchKey,
        }

        dispatch(receiveNextPageTimeOffBalances(params))
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        dispatch(receiveTimeOffBalancesUniqueYears())
    }, [])

    useEffect(() => {
        getTimeOffBalances(1)
    }, [year])

    useEffect(() => {
        if (searchKey !== null) {
            const delayDebounceFn = setTimeout(() => {
                getTimeOffBalances(1)
            }, 500)

            return () => clearTimeout(delayDebounceFn)
        }
    }, [searchKey])

    return (
        <>
            <Helmet>
                <title>Time Off Balance - Newsoft Inside</title>
            </Helmet>
            <div className="time-offs-balance">
                <div className="time-offs-balance-header">
                    <SearchForm value={searchKey}
                        onClick={() => setSearchKey("")}
                        onChange={(e) => setSearchKey(e.target.value)}/>
                    <div className="time-offs-balance-header-select-year">
                        <DDListYear
                            options={uniqueYears.sort((a, b) => b.year - a.year).map((x) => ({value: x.year, label: x.year}))}
                            defaultValue={{value: new Date().getFullYear(), label: new Date().getFullYear()}}
                            onChange={(year) => setYear(year.value)}
                        />
                    </div>
                </div>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={getNextTimeOffBalances}
                    hasMore={!tableLoading && timeOffBalances.length < total}
                    loader={<StyledCircularProgress key="circular-progress"/>}
                    datalength={timeOffBalances.length}
                >
                    {loading ? <StyledCircularProgress/> : <div className="time-offs-balance-content">
                        <TimeOffBalanceTable
                            year={year}
                            data={timeOffBalances}
                        />
                    </div>}
                </InfiniteScroll>
            </div>
        </>
    )
}

export default TimeOffBalance
