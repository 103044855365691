import React, {useState} from "react"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"

import EditTimeRecordPopup from "./EditTimeRecordPopup"
import DeleteTimeRecordPopup from "../../TimeRecords/components/DeleteTimeRecordPopup"

import {formatDate, minutesToString} from "../../../utils/datetime.utils"
import {canEditTimeRecord} from "../../../utils/permission.utils"

import {
    DD_MM_YYYY,
    ERROR_CODE, HOURS_TYPE,
    HOURS_TYPE_ONLY_TIME_OFF_OPTIONS,
    MOBILE_WIDTH, SUCCESS_CODE,
    TIME_RECORD_STATUS
} from "../../../constants/other"
import InfoPopup from "../../../components/InfoPopup"
import {PERMISSIONS} from "../../../constants/permissions"
import {getColorByHoursType} from "../../../utils/other.utils"
import DeleteTimeOffRequestPopup from "../../TimeOffRequests/components/DeleteTimeOffRequestPopup"
import {useMediaQuery} from "react-responsive"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import PushPinIcon from "@mui/icons-material/PushPin"
import {receiveUserTimeRecordTemplates} from "../actions/timeRecordTemplatesActions"
import {createTimeRecordTemplate} from "../api"
import CustomScrollableTable from "../../../toolkits/CustomScrollableTable"

const TimeRecordsTable = ({timeRecords, timeOffRequests, timeOffTabActive, refreshData, setUserMessage, setRequestOvertimeCoefficient, setDateForOvertimeRequest}) => {
    const {hasPermission} = useSelector((state) => state.profile)
    const [timeOffsVisible, setTimeOffsVisible] = useState(false)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const auth = useSelector((state) => state.auth)
    const userId = auth.user.uid
    const dispatch = useDispatch()

    const toggleTimeOffsVisibility = () => {
        setTimeOffsVisible(!timeOffsVisible)
    }

    const timeOffHoursTypes = HOURS_TYPE_ONLY_TIME_OFF_OPTIONS.map(option => option.value)

    const isDisabledEdit = timeRecords.every(tr => !hasPermission(PERMISSIONS.WRITE_TIME_RECORD) && (
        !hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) ||
        !canEditTimeRecord(tr.date) ||
        timeOffHoursTypes.includes(tr.hours_type)
    ))

    const isDisabledDelete = timeRecords.every(tr => !hasPermission(PERMISSIONS.WRITE_TIME_RECORD) && (
        !hasPermission(PERMISSIONS.WRITE_OWN_TIME_RECORD) ||
        (!canEditTimeRecord(tr.date) && tr.status !== TIME_RECORD_STATUS.IN_REVIEW) ||
        timeOffHoursTypes.includes(tr.hours_type)
    ))

    const handleCreateTimeRecordTemplate = (projectId, duration) => {
        const data = {
            user_id: userId,
            project_id: projectId,
            duration: duration,
        }
        createTimeRecordTemplate(data)
            .then(() => {
                setUserMessage({message: "Time record template was successfully created", code: SUCCESS_CODE})
                dispatch(receiveUserTimeRecordTemplates(userId))
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const timeRecordsList = timeRecords.map((tr) => {
        const popupParams = {
            setUserMessage: setUserMessage,
            timeRecord: tr,
            refreshData: refreshData,
            setRequestOvertimeCoefficient: setRequestOvertimeCoefficient,
            setDateForOvertimeRequest: setDateForOvertimeRequest,
        }
        return (
            <>
                <tr className="custom-scrollable-table-row time-tracker-content-records-table-item">
                    <td className="t-b2">
                        {tr.hours_type != HOURS_TYPE.Overtime &&
                        <PushPinIcon onClick={() => handleCreateTimeRecordTemplate(tr.project.id, tr.duration)} className="time-tracker-content-records-table-item-pin-icon"/>}
                        {formatDate(tr.date, DD_MM_YYYY)}
                        {tr.status == TIME_RECORD_STATUS.IN_REVIEW && <p className="time-tracker-content-records-table-item-label t-b3">in review</p>}
                    </td>
                    <td className="t-b2">
                        <li style={{backgroundColor: getColorByHoursType(tr.type, tr.approved)}} className="dot-types"></li>
                        {tr.hours_type}</td>
                    <td className="t-b2">
                        {tr.project?.name &&
                    <Popup trigger={
                        <span className="cell-limited-text-small-table t-b2 clickable">{tr.project.name}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title="Project"
                                value={tr.project.name}
                                close={close}
                            />
                        )}
                    </Popup>
                        }
                    </td>
                    <td className="t-b2">{minutesToString(tr.duration)}</td>
                    <td>
                        {tr.note &&
                    <Popup trigger={
                        <span className="cell-limited-text-small-table t-b2 clickable">{tr.note}</span>
                    } modal>
                        {close => (
                            <InfoPopup
                                title="Note"
                                value={tr.note}
                                close={close}
                            />
                        )}
                    </Popup>
                        }
                    </td>
                    <td>
                        {!isDisabledEdit ? <>
                            {!timeOffHoursTypes.includes(tr.hours_type)
                                &&
                        <Popup trigger={
                            <span className="t-b2 time-tracker-content-records-table-link">edit</span>
                        } modal>
                            {close => (
                                <EditTimeRecordPopup
                                    {...popupParams}
                                    close={close}
                                />
                            )}
                        </Popup>
                            } </> : <></>}
                        {!isDisabledDelete ? <>
                            {(hasPermission(PERMISSIONS.WRITE_TIME_RECORD) || !timeOffHoursTypes.includes(tr.hours_type)) &&
                            <Popup trigger={
                                <span className="t-b2 time-tracker-content-records-table-link">delete</span>
                            } modal>
                                {close => (
                                    <DeleteTimeRecordPopup
                                        {...popupParams}
                                        close={close}
                                    />
                                )}
                            </Popup>
                            }
                        </> : <></>}
                    </td>
                </tr>
            </>
        )
    })

    const timeOffRequestsList = timeOffRequests.map((timeOff, index) => {
        const popupParams = {
            time_off_request: timeOff,
            refreshTable: refreshData,
            setUserMessage: setUserMessage,
        }

        const deleteAction = (
            <Popup trigger={<span className="t-b2 time-tracker-content-records-table-link">delete</span>} modal>
                {close => (
                    <DeleteTimeOffRequestPopup
                        {...popupParams}
                        close={close}
                    />
                )}
            </Popup>
        )

        const approvalStatus = timeOff.approved === null && timeOff.instance == "TimeOffRequest" ? (
            deleteAction
        ) : timeOff.approved ? (
            <span className="approved-time-off-requests-span t-h3">APPROVED</span>
        ) : (
            !timeOff.approved && <span className="declined-time-off-requests-span t-h3">DECLINED</span>
        )

        return (
            <tr key={`${timeOff.id}_${index}`} className="custom-scrollable-table-row time-tracker-content-records-table-item">
                <td className="t-b2">{timeOff.period} {timeOff.approved === null && <p className="time-tracker-content-records-table-item-label t-b3">in review</p>}</td>
                <td className="t-b2">
                    <li style={{backgroundColor: getColorByHoursType(timeOff.type, timeOff.approved)}} className="dot-types"></li>
                    {timeOff.type}</td>
                <td className="t-b2">{timeOff.count} {timeOff.count == 1 ? "day" :  "days"}</td>
                <td className="t-b2">
                    <Popup trigger={<span className="cell-limited-text-small-table t-b2 clickable">{timeOff.note}</span>} modal>
                        {close => (
                            <InfoPopup
                                title="Note"
                                value={timeOff.note}
                                close={close}
                            />
                        )}
                    </Popup>
                </td>
                <td className="t-b2">
                    {approvalStatus}
                </td>
            </tr>
        )
    })

    const mobileTimeOffRequestsList = timeOffsVisible ? timeOffRequestsList : [timeOffRequestsList[0]]

    const recordsListToShow = timeOffTabActive ? (isMobile ? mobileTimeOffRequestsList : timeOffRequestsList) : timeRecordsList

    return (
        <CustomScrollableTable>
            <table className="time-tracker-content-records-table">
                <thead>
                    <tr>
                        <th className="t-h3">date</th>
                        <th className="t-h3">{timeOffTabActive ? "Time Off Type" : "Work Time Type"}</th>
                        {!timeOffTabActive &&
                        <th className="t-h3">project</th>}
                        <th className="t-h3">duration</th>
                        <th className="t-h3">note</th>
                        <th className="t-h3">ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {recordsListToShow}
                </tbody>
            </table>
            {isMobile && timeOffTabActive && timeOffRequests.length > 1 &&
            <div className="time-tracker-content-records-table-arrow">
                <KeyboardArrowDownIcon
                    className={`time-off-arrow-down-icon ${timeOffsVisible ? "expanded" : ""}`}
                    onClick={toggleTimeOffsVisibility}/>
            </div>
            }
        </CustomScrollableTable>
    )
}

export default TimeRecordsTable
