import {getUserEquipment} from "../api"
import {
    GET_USER_EQUIPMENT_REQUEST,
    GET_USER_EQUIPMENT_SUCCESS,
    GET_USER_EQUIPMENT_FAILURE
} from "../const"


const request = () => ({ type: GET_USER_EQUIPMENT_REQUEST })

const success = (userEquipment) => ({
    type: GET_USER_EQUIPMENT_SUCCESS,
    payload: {userEquipment}
})

const failure = () => ({ type: GET_USER_EQUIPMENT_FAILURE })

export const receiveUserEquipment = (userId) => async (dispatch) => {
    dispatch(request())
    try {
        const { data: receiveEquipmentData } = await getUserEquipment(userId)

        dispatch(success(receiveEquipmentData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
