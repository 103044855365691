import "./SearchForm.scss"
import SearchIcon from "@mui/icons-material/Search"
import CloseIcon from "@mui/icons-material/Close"


import React, { createRef, useContext } from "react"
import {useMediaQuery} from "react-responsive"

import {DESKTOP_WIDTH} from "../../constants/other"
import { ThemeContext } from "../../styles/theme-context"


const SearchForm = ({value, onClick, onChange, className, user}) => {
    const theme = useContext(ThemeContext)
    const isDesktop = useMediaQuery({maxWidth: DESKTOP_WIDTH})
    const inputRef = createRef()

    const onResetField = (e) => {
        onClick(e)
        inputRef.current.focus()
    }

    return (
        <form
            className={`search-input-form ${className ? className : " "}`}
            onSubmit={(e) => e.preventDefault()}
        >
            <input
                type="search"
                className={`search-input ${theme.searchClassName}`}
                ref={inputRef}
                placeholder={(value && !user) ? "" : "Search"}
                value={(value && !user) ? value : ""}
                onChange={onChange}
                autoFocus={!isDesktop}
            />
            {value
                ? <CloseIcon onClick={(e) => onResetField(e)} className="search-icon" />
                : <SearchIcon className="search-icon" alt="search"/>}
            {value && user &&  <span className="search-user-wrap t-s3"> {value ? value : ""} </span>}
        </form>
    )
}

export default SearchForm
