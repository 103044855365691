import {getUserProjects} from "../api"
import {
    GET_USER_ALL_PROJECTS,
    GET_USER_PROJECTS
} from "../const"



const success = (receiveProjectsData) => ({
    type: GET_USER_PROJECTS,
    payload: {receiveProjectsData}
})

const successAll = (receiveAllProjectsData) => ({
    type: GET_USER_ALL_PROJECTS,
    payload: {receiveAllProjectsData}
})

export const receiveUserProjects = (userId, params) => async (dispatch) => {
    try {
        const { data: receiveProjectsData } = await getUserProjects(userId, params)

        dispatch(success(receiveProjectsData))
    } catch (e) {
        console.log(e)
    }
}

export const receiveAllUserProjects = (userId, params) => async (dispatch) => {
    try {
        const { data: receiveAllProjectsData } = await getUserProjects(userId, params)
        dispatch(successAll(receiveAllProjectsData))
    } catch (e) {
        console.log(e)
    }
}
