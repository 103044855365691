export const GET_TIME_OFF_BALANCES_REQUEST = "GET_TIME_OFF_BALANCES_REQUEST"
export const GET_TIME_OFF_BALANCES_SUCCESS = "GET_TIME_OFF_BALANCES_SUCCESS"
export const GET_TIME_OFF_BALANCES_FAILURE = "GET_TIME_OFF_BALANCES_FAILURE"
export const GET_NEXT_PAGE_TIME_OFF_BALANCES_REQUEST = "GET_NEXT_PAGE_TIME_OFF_BALANCES_REQUEST"
export const GET_TIME_OFF_USAGE_INFO_SUCCESS = "GET_TIME_OFF_USAGE_INFO_SUCCESS"
export const GET_TIME_OFF_USAGE_INFO_REQUEST = "GET_TIME_OFF_USAGE_INFO_REQUEST"
export const GET_TIME_OFF_USAGE_INFO_FAILURE = "GET_TIME_OFF_USAGE_INFO_FAILURE"

export const GET_TIME_OFF_BALANCES_UNIQUE_YEARS_REQUEST = "GET_TIME_OFF_BALANCES_UNIQUE_YEARS_REQUEST"
export const GET_TIME_OFF_BALANCES_UNIQUE_YEARS_SUCCESS = "GET_TIME_OFF_BALANCES_UNIQUE_YEARS_SUCCESS"
export const GET_TIME_OFF_BALANCES_UNIQUE_YEARS_FAILURE = "GET_TIME_OFF_BALANCES_UNIQUE_YEARS_FAILURE"
