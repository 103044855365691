import firebase from "../../../adapters/firebase"

import {receiveLocalStorage} from "./localStorageActions"
import {getSettings} from "../../Settings/api"
import {getMe, getUserById} from "../../Profile/api"
import {successSettings} from "../../Settings/actions/settingsActions"
import {successGetMe} from "../../Profile/actions/userActions"
import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import {getAuthorizedUserData, getUserViewedAs, saveAuthorizedUserData} from "../../../utils/localstorage.utils"
import {extractSettingsByName} from "../../../utils/other.utils"

import {LOCALSTORAGE_KEYS, SETTINGS} from "../../../constants/other"
import {
    AUTH_ERRORS,
    FIREBASE_GET_USER_REQUEST,
    FIREBASE_GET_USER_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
} from "../const"
import {receiveUserNotifications} from "../../Notifications/actions/userNotificationsActions"
import {PERMISSIONS} from "../../../constants/permissions"


const requestFirebaseGetUser = () => ({type: FIREBASE_GET_USER_REQUEST})
const successFirebaseGetUser = (user) => ({
    type: FIREBASE_GET_USER_SUCCESS,
    payload: {user}
})

const requestSignIn = () => ({type: LOGIN_REQUEST})
const successSignIn = (claims) => ({
    payload: {claims},
    type: LOGIN_SUCCESS,
})
const failureSignIn = (error) => ({type: LOGIN_FAILURE, payload: {error}})


const requestSignOut = () => ({type: LOGOUT_REQUEST})
const successSignOut = () => ({type: LOGOUT_SUCCESS})

export const retrieveSettings = (userId) => async (dispatch) => {
    const userViewedAs = getUserViewedAs()

    let [settingsData, userData] = await Promise.all([
        getSettings(),
        getMe(),
    ])

    if (userData.data.archived === true) {
        throw {code: AUTH_ERRORS.USER_ARCHIVED}
    }

    if(userViewedAs){
        const permissionNames = userData.data.permissions.map(permission => permission.permission_name)
        if (!permissionNames.includes(PERMISSIONS.READ_ROLE) && !permissionNames.includes(PERMISSIONS.WRITE_ROLE)){
            return
        }
        userData = await getUserById(userViewedAs.user_id)
    }
    
    await dispatch(successGetMe(userData.data))

    await Promise.all([
        dispatch(receiveNotifications()),
        dispatch(receiveUserNotifications(userId))
    ])

    dispatch(successSettings({
        accessToTimeTracker: extractSettingsByName(settingsData.data, SETTINGS.ACCESS_TO_TIME_TRACKER),
        defaultTimeOffsPerYear: extractSettingsByName(settingsData.data, SETTINGS.DEFAULT_TIME_OFFS_PER_YEAR),
        maxTimeOffsPerYear: extractSettingsByName(settingsData.data, SETTINGS.MAX_TIME_OFFS_PER_YEAR),
        upcomingPerformanceReview: extractSettingsByName(settingsData.data, SETTINGS.UPCOMING_PERFORMANCE_REVIEW),
        maxDaysStoreNotifications: extractSettingsByName(settingsData.data, SETTINGS.MAX_DAYS_STORE_NOTIFICATIONS),
        accessToEditBonus: extractSettingsByName(settingsData.data, SETTINGS.ACCESS_TO_EDIT_BONUS),
        websiteTheme: extractSettingsByName(settingsData.data, SETTINGS.WEBSITE_THEME),
        newcoinsEnabled: extractSettingsByName(settingsData.data, SETTINGS.NEWCOINS_ENABLED),
        shopEnabled: extractSettingsByName(settingsData.data, SETTINGS.SHOP_ENABLED),
        autoGiftBirthday: extractSettingsByName(settingsData.data, SETTINGS.AUTO_GIFT_BIRTHDAY),
        autoGiftAnniversary: extractSettingsByName(settingsData.data, SETTINGS.AUTO_GIFT_ANNIVERSARY),
        autoGiftLoggedHours: extractSettingsByName(settingsData.data, SETTINGS.AUTO_GIFT_LOGGED_HOURS)
    }))

    saveAuthorizedUserData({
        accessToTimeTracker: extractSettingsByName(settingsData.data, SETTINGS.ACCESS_TO_TIME_TRACKER),
        roles: userData.data.roles.map((role) => role.role_name),
        privateMode: userData.data.private_mode,
    })

    dispatch(receiveLocalStorage())

    return userData.data
}

export const firebaseGetUser = () => async (dispatch) => {
    dispatch(requestFirebaseGetUser())

    await firebase.onAuthStateChanged(async (user) => {
        if (user) {
            // This will be executed when user was logged in some time ago and his data is saved in localstorage
            if (getAuthorizedUserData()) {
                const dbUser = await dispatch(retrieveSettings(user.uid))
                await dispatch(successGetMe(dbUser))
            }
            dispatch(successFirebaseGetUser(user))
        } else {
            if(getAuthorizedUserData()){
                dispatch(signOut())
                window.location.href = "/login"
            }
        }
    })
}

export const signIn = (body) => async (dispatch) => {
    dispatch(requestSignIn())

    try {
        const credentials = await firebase.signInWithPassword(body.email, body.password)
        await dispatch(retrieveSettings(credentials.user.uid))

        credentials.user.getIdTokenResult()
            .then((result) => {
                dispatch(successSignIn(result.claims))
            })
    } catch (e) {
        if (e.code === AUTH_ERRORS.USER_NOT_FOUND || e.code === AUTH_ERRORS.WRONG_PASSWORD) {
            dispatch(failureSignIn("Incorrect email or password, please try again"))
        } else if (e.code === AUTH_ERRORS.USER_ARCHIVED) {
            dispatch(failureSignIn("The user is archived"))
        } else {
            dispatch(failureSignIn("Unexpected login error"))
        }
        throw Error()
    }
}

export const signInWithGoogle = () => async (dispatch) => {
    dispatch(requestSignIn())

    try {
        const credentials = await firebase.signInWithGoogle()

        await dispatch(retrieveSettings(credentials.user.uid))

        credentials.user.getIdTokenResult()
            .then((result) => {
                dispatch(successSignIn(result.claims))
            })
    } catch (e) {
        if (e.code === AUTH_ERRORS.POP_UP_CLOSED) {
            dispatch(failureSignIn())
        }  else if (e.code === AUTH_ERRORS.POP_UP_CLOSED || e.code === AUTH_ERRORS.WRONG_PASSWORD) {
            dispatch(failureSignIn("Incorrect email or password, please try again"))
        } else if (e.code === AUTH_ERRORS.USER_ARCHIVED) {
            dispatch(failureSignIn("The user is archived"))
        } else if (e.code === AUTH_ERRORS.CANNOT_CREATE_USER) {
            dispatch(failureSignIn("User does not exist"))
        } else if (e?.response?.data?.message){
            dispatch(failureSignIn(e.response.data.message))
        } else {
            dispatch(failureSignIn("Unexpected login error"))
        }
        throw Error()
    }
}

export const signOut = () => async (dispatch) => {
    dispatch(requestSignOut())

    localStorage.removeItem(LOCALSTORAGE_KEYS.SETTINGS)
    localStorage.removeItem(LOCALSTORAGE_KEYS.VIEW_AS_MEMBER_MODE)
    firebase.signOut()
    dispatch(successSignOut())
}
