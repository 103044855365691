import React from "react"

import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {daysDifference, normalizeDate} from "../../../utils/datetime.utils"
import {nthNumberSuffix} from "../../../utils/other.utils"

import {
    AUTO_GIVEN_NEWCOINS,
    CACHED_IMAGES,
    DAYS_PER_YEAR, DESKTOP_WIDTH,
    GREETINGS_COUNT_DAYS_SHOW, SETTINGS,
} from "../../../constants/other"
import SettingsEnabled from "../../SettingsEnabled/SettingsEnabled"
import {useSelector} from "react-redux"
import {useMediaQuery} from "react-responsive"


const AnniversaryPopup = ({hiringDate, close}) => {
    const today = new Date()
    const coinsSettings = useSelector((state) => state.profile.myInfo.coins_settings)
    const daysDelta = daysDifference(normalizeDate(new Date(hiringDate)), normalizeDate(today))
    const anniversaryYear = Math.floor((daysDelta + GREETINGS_COUNT_DAYS_SHOW) / DAYS_PER_YEAR)
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <div className="greetings-anniversary">
            <div className="greetings-anniversary-content">
                <span className="greetings-anniversary-year">
                    {anniversaryYear}
                    <div className="greetings-anniversary-year-info">
                        <span className="t-h1">
                            {nthNumberSuffix(anniversaryYear)}
                        </span>
                        <span className="t-h1">Anniversary</span>
                        <span className="t-s1">in company</span>
                    </div>
                    <img src={CACHED_IMAGES.LogoNS} className="greetings-anniversary-logo-ns"/>
                </span>
            </div>

            <div className="greetings-anniversary-thanks">
                <span className="t-s2">Thanks for your efforts and contribution.<br/></span>
                {!coinsSettings["birthday"] && <span className="t-s2">Newsoft team.</span>}
                <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
                    {coinsSettings && coinsSettings["anniversary"] &&
                        (<span className={`greetings-anniversary-content-newcoins ${isDesktop ? "t-h1" : "t-s4"}`}>
                            {`You have received ${AUTO_GIVEN_NEWCOINS.find(item => item.setting_name === "anniversary").amount} Newcoins 🎁`}
                        </span>)}
                </SettingsEnabled>
            </div>

            <SmallButton onClick={close}>
                THANK YOU
            </SmallButton>
        </div>
    )
}

export default AnniversaryPopup
