import { getUserTimeOffRecords, getUserTimeRecords } from "../api"
import {
    GET_TIME_RECORDS_REQUEST,
    GET_TIME_RECORDS_SUCCESS,
    GET_TIME_RECORDS_FAILURE,
    GET_TIME_OFF_RECORDS_REQUEST,
    GET_TIME_OFF_RECORDS_FAILURE,
    GET_TIME_OFF_RECORDS_SUCCESS
} from "../const"

const request = () => ({ type: GET_TIME_RECORDS_REQUEST })

const success = (timeRecords) => ({
    type: GET_TIME_RECORDS_SUCCESS,
    payload: { timeRecords },
})

const failure = () => ({ type: GET_TIME_RECORDS_FAILURE })

export const receiveTimeRecords = (userId, params) => async (dispatch) => {
    dispatch(request())
    try {
        const { data: timeRecords } = await getUserTimeRecords(userId, params) 

        dispatch(success(timeRecords))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

const timeOffRecordsRequest = () => ({type: GET_TIME_OFF_RECORDS_REQUEST})
const timeOffRecordsFailure = () => ({type: GET_TIME_OFF_RECORDS_FAILURE})

const timeOffRecordsSuccess = (timeOffRecords) => ({
    type: GET_TIME_OFF_RECORDS_SUCCESS,
    payload: {timeOffRecords},
})
export const receiveTimeOffRecords = (user_id, params) => async (dispatch) => {
    dispatch(timeOffRecordsRequest())
    try {
        const {data: timeOffRecords} = await getUserTimeOffRecords(user_id, params)
        dispatch(timeOffRecordsSuccess(timeOffRecords))
    } catch (e) {
        dispatch(timeOffRecordsFailure())
        console.log(e)
    }
}
