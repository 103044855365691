import "./index.scss"

import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import Helmet from "react-helmet"
import InfiniteScroll from "react-infinite-scroll-component"

import {
    receiveNextPageTimeRecords,
    receiveNextPageUserTimeRecords,
    receiveTimeRecords,
    receiveUserTimeRecords,
} from "./actions/timeRecordsActions"

import TimeRecordsTable from "./components/TimeRecordsTable"

import StyledCircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import CircularProgress from "../../toolkits/CircularProgress/CircularProgress"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {FILTER_BY_PROJECTS_NOTE, FILTER_TYPES, PAGE_SIZE, TIME_RECORD_STATUS} from "../../constants/other"
import {getSavedTimeRecordsRange, getUserViewedAs} from "../../utils/localstorage.utils"
import { TimeRecordsPeriodFilter } from "./components/TimeRecordsPeriodFilter"
import { formatDate, mapDateRangeFilters } from "../../utils/datetime.utils"
import MultipleSelect from "../../toolkits/Multiselect"
import { useMediaQuery } from "react-responsive"
import { receiveAllUserProjects } from "../Profile/actions/projectsActions"
import { DESKTOP_WIDTH } from "../../constants/other"
import InfoMessage from "../../components/InfoMessage"

export const TimeRecords = ({location}) => {
    const isMainPage = location.pathname === "/time-record-requests"
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const dispatch = useDispatch()

    const userId = getUserViewedAs() ? getUserViewedAs().user_id : location.state?.userId

    const {
        tableLoading,
        timeRecords,
        total,
    } = useSelector((state) => state.userTimeRecords)
    const projects = useSelector(state => state.profile.allProjects)
    const hasProjects = projects.length !== 0

    const [selectedProjectIds, setSelectedProjectIds] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [userMessage, setUserMessage] = useState(null)
    const [approvedRecords, setApprovedRecords] = useState([])

    const savedTimeRecordsRange = getSavedTimeRecordsRange()
    const [dateFilter, setDateFilter] = useState(Object.values(FILTER_TYPES).includes(savedTimeRecordsRange) ? mapDateRangeFilters(savedTimeRecordsRange) : mapDateRangeFilters(FILTER_TYPES.THIS_MONTH))
    const isAllProjectsSelected = selectedProjectIds.length === projects.length || selectedProjectIds.length === 0

    const getTimeRecords = (page) => {
        page = page ? page : currentPage
        let params = {
            size: PAGE_SIZE,
            page: page,
            all: true,
            project_in: isAllProjectsSelected ? null : selectedProjectIds.toString(),
        }

        if (!isMainPage) {
            params = {
                created_after: formatDate(dateFilter.created_after),
                created_before: formatDate(dateFilter.created_before), 
                project_in: isAllProjectsSelected ? null : selectedProjectIds.toString()
            }
        }

        dispatch(userId
            ? receiveUserTimeRecords(userId, params)
            : receiveTimeRecords({status_in: TIME_RECORD_STATUS.IN_REVIEW, ...params})
        )
        setCurrentPage(page + 1)
    }

    useEffect(() => {
        dispatch(receiveAllUserProjects(userId, {ended_: true}))
    }, [])

    const getNextTimeRecords = () => {
        let params = {
            size: PAGE_SIZE,
            page: currentPage,
            all: true,
        }

        if (!isMainPage) {
            params = {
                created_after: formatDate(dateFilter.created_after),
                created_before: formatDate(dateFilter.created_before),
                project_in: isAllProjectsSelected ? null : selectedProjectIds.toString()
            }
        }
        dispatch(userId
            ? receiveNextPageUserTimeRecords(userId, params)
            : receiveNextPageTimeRecords({status_in: TIME_RECORD_STATUS.IN_REVIEW, ...params})
        )
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getTimeRecords(1)
    }, [userId, dateFilter, selectedProjectIds])

    return (
        <>
            <Helmet>
                <title> {`Time Record${isMainPage ? " Requests" : "s"}`} - Newsoft Inside </title>
            </Helmet>
            <div className="time-records">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message}
                            code={userMessage.code}/>
                    }
                </div>
                {!isMainPage && 
                <div className="time-records-filters">
                    <TimeRecordsPeriodFilter period={dateFilter} setPeriod={setDateFilter} userId={userId}/>
                    {isDesktop && hasProjects &&
                        <div className="time-records-filters-projects">
                            {selectedProjectIds.length !== 0 && selectedProjectIds.length !== projects.length &&
                                <InfoMessage text={FILTER_BY_PROJECTS_NOTE} />}
                            {projects.length > 1 &&
                                <>
                                    <span className="time-records-filters-projects-filter-text t-s4">Project:</span>
                                    <MultipleSelect placeholder="Choose the project" loading={tableLoading} options={projects.map(p => p.project)} selectedOptionIds={selectedProjectIds} setSelectedOptionIds={setSelectedProjectIds}/>
                                </>
                            }
                        </div>
                    }
                </div>
                }
                <InfiniteScroll
                    pageStart={0}
                    next={getNextTimeRecords}
                    hasMore={!tableLoading && timeRecords.length < total}
                    loader={<CircularProgress key="circular-progress"/>}
                    dataLength={timeRecords.length - approvedRecords.length}
                    useWindow={false}
                >
                    {tableLoading ? <div><br/><StyledCircularProgress/></div> :
                        <div className="time-records-content">
                            <TimeRecordsTable
                                timeRecords={timeRecords.filter(record =>
                                    !approvedRecords.includes(record.id))
                                }
                                refreshTable={() => getTimeRecords(1)}
                                setUserMessage={setUserMessage}
                                isMainPage={isMainPage}
                                userId={userId}
                                setApprovedRecords={setApprovedRecords}
                            />
                        </div>
                    }
                </InfiniteScroll>
            </div>
        </>
    )
}

export default TimeRecords
