import {getEmployeePositions} from "../api"

import {
    GET_EMPLOYEE_POSITIONS_REQUEST,
    GET_EMPLOYEE_POSITIONS_SUCCESS,
    GET_EMPLOYEE_POSITIONS_FAILURE,
    GET_NEXT_PAGE_EMPLOYEE_POSITIONS_REQUEST,
} from "../const"


const request = () => ({type: GET_EMPLOYEE_POSITIONS_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_EMPLOYEE_POSITIONS_REQUEST})

const success = (employeePositions) => ({
    type: GET_EMPLOYEE_POSITIONS_SUCCESS,
    payload: {employeePositions},
})

const failure = () => ({type: GET_EMPLOYEE_POSITIONS_FAILURE})


export const receiveEmployeePositions = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveEmployeePositionsData} = await getEmployeePositions(params)

        dispatch(success(receiveEmployeePositionsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageEmployeePositions = (params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveNextPageEmployeePositionsData} = await getEmployeePositions(params)
        dispatch(success(receiveNextPageEmployeePositionsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
