import http from "../../http"
import {API_V1_URL} from "../../constants/other"

const TIME_RECORDS_URL = `${API_V1_URL}/time-records`
const TIME_RECORD_TEMPLATES_URL = `${API_V1_URL}/time-record-templates`
const USERS_URL = `${API_V1_URL}/users`
const NOTIFICATION_URL = `${API_V1_URL}/notifications`
const TIME_OFF_BALANCE_URL = `${API_V1_URL}/time-off-balance/`


export const getTimeTrackerDetails = (userId, params) => http.get(`${TIME_RECORDS_URL}/time-tracker-details/${userId}`, {params})

export const getCalendarDetails = (userId, params) => http.get(`${TIME_RECORDS_URL}/calendar-data/${userId}`, {params})

/**
 * @desc API method for receiving user time recors.
 * Method [GET]
 * @param {string} userId - we should pass unic user id
 * @param {object} params - all params, that can be passed" {createdAfter, createdBefore, createdAt, hoursType, page, size}
 */
export const getUserTimeRecords = (userId, params) => http.get(`${USERS_URL}/${userId}/time-records`, {
    params: {
        ...params,
        size: "1000"
    }
})

export const getUserProjects = (userId, all_) => http.get(`${USERS_URL}/${userId}/projects`, {params: {all_}})

export const createTimeRecord = (data) => http.post(`${TIME_RECORDS_URL}/`, {data})

export const requestOvertimeCoeff = (data) => http.post(`${NOTIFICATION_URL}/request-overtime-coefficient`, {data})

export const getUserTimeRecordTemplates = (userId) => http.get(`${TIME_RECORD_TEMPLATES_URL}/`, {params: {user_id: userId}})

export const createTimeRecordTemplate = (data) => http.post(`${TIME_RECORD_TEMPLATES_URL}/`, {data})

export const updateTimeRecordTemplate = (id, data) => http.put(`${TIME_RECORD_TEMPLATES_URL}/${id}`, {data})

export const deleteTimeRecordTemplate = (id) => http.delete(`${TIME_RECORD_TEMPLATES_URL}/${id}`)

export const duplicateTimeRecords = (data) => http.post(`${TIME_RECORDS_URL}/duplicate-time-records`, {data})

export const getUserTimeOffRecords = (user_id, params) => http.get(`${TIME_OFF_BALANCE_URL}time-offs-usage-info/${user_id}`, {params})
