import React, {useState} from "react"
import {DateObject} from "react-multi-date-picker"

import {updateTimeOffRequestsById} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"
import Textarea from "../../../toolkits/Textarea/Textarea"
import {RangeDatePicker} from "../../../toolkits/Calendar/RangeDatePicker"

import {formatDate} from "../../../utils/datetime.utils"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const EditTimeOffRequestPopup = ({time_off_request, refreshTable, setUserMessage, close}) => {
    const [note, setNote] = useState(time_off_request.note)
    const [dates, setDates] = useState([new DateObject(time_off_request.start_date), new DateObject(time_off_request.end_date)])
    const fullName = `${time_off_request.user.first_name} ${time_off_request.user.last_name}`

    const handleSubmit = () => {
        const data = {
            start_date: formatDate(dates[0].toDate()),
            end_date: formatDate(dates[1].toDate()),
            note: note,
        }

        updateTimeOffRequestsById(time_off_request.id, data)
            .then(() => {
                refreshTable()
                setUserMessage({message: "Time off request was successfully edited!", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup isSubtitle={true} text_title={`Do you want to edit ${fullName} time off request?`}>
            <div className="vacation-requests-popup-content">
                <RangeDatePicker
                    label="Date"
                    calendarTitle="EDIT TIME OFF"
                    value={dates}
                    onChange={(selectedDates) => setDates(selectedDates)}
                />
                <Textarea
                    text_label="Note"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                />
            </div>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton type="submit" onClick={handleSubmit}> EDIT </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default EditTimeOffRequestPopup
