import React from "react"

import {deleteTimeOffRequestById} from "../api"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"
import {receiveMyInfo} from "../../Profile/actions/userActions"
import {useDispatch} from "react-redux"


const DeleteTimeOffRequestPopup = ({time_off_request, refreshTable, setUserMessage, close}) => {
    const dispatch = useDispatch()

    const handleSubmit = () => {
        deleteTimeOffRequestById(time_off_request.id)
            .then(() => {
                refreshTable()
                dispatch(receiveMyInfo())
                setUserMessage({message: "Time off request was successfully deleted", code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to delete ${time_off_request.user ? `${time_off_request.user.first_name} ${time_off_request.user.last_name}` : ""} time off request?`} isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteTimeOffRequestPopup
