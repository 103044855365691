import http from "../../http"

import {API_V1_URL} from "../../constants/other"


const TIME_RECORDS_URL = `${API_V1_URL}/time-records/`

const USERS_URL = `${API_V1_URL}/users`

/**
 * @desc API method for receiving user time records.
 * Method [GET]
 * @param {string} userId - we should pass unic user id
 * @param {object} params - all params, that can be passed" {createdAfter, createdBefore, createdAt, hoursType, page, size}
 */

export const getTimeRecords = (params) => http.get(TIME_RECORDS_URL, {params})

export const getUserTimeRecords = (userId, params) => 
    http.get(`${USERS_URL}/${userId}/time-records`, {params})

export const deleteTimeRecordById = (timeRecordId) => http.delete(`${TIME_RECORDS_URL}${timeRecordId}`)

export const updateTimeRecordById = (timeRecordId, data) => http.put(`${TIME_RECORDS_URL}${timeRecordId}`, {data})
