import React from "react"
import {useDispatch, useSelector} from "react-redux"

import moment from "moment"

import {receiveCalendarData} from "../../pages/TimeTracker/actions/calendarDataActions"

import {endOfMonth, formatDate, startOfMonth} from "../../utils/datetime.utils"

import {DAYS_PER_WEEK} from "../../constants/other"

import Arrow from "@mui/icons-material/KeyboardArrowLeft"
import {getUserViewedAs} from "../../utils/localstorage.utils"


export const CustomButton = ({direction, handleClick, calendarRef}) => {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : auth.user.uid

    const onArrowClick = () => {
        handleClick()
        let startDate, endDate
        if (calendarRef) {
            startDate = moment(
                startOfMonth(calendarRef.current.date.toDate())
            ).add(-DAYS_PER_WEEK, "days").toDate()
            endDate = moment(
                endOfMonth(calendarRef.current.date.toDate())
            ).add(DAYS_PER_WEEK, "days").toDate()
        } else {
            const year = moment().year()

            startDate = moment().year(year).startOf("year").toDate()
            endDate = moment().year(year).endOf("year").toDate()
        }
        dispatch(receiveCalendarData(userId, {
            start_date: formatDate(startDate),
            end_date: formatDate(endDate)
        }))
    }
    return direction === "right" ? (
        <Arrow onClick={onArrowClick} className="cursor-pointer cursor-pointer-right"/>
    ) : (
        <Arrow onClick={onArrowClick} className="cursor-pointer cursor-pointer-left"/>
    )
}
