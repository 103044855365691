import "./index.scss"
import UserImage from "../../assets/images/user_photo.png"

import React, {useEffect, useState, useRef} from "react"
import Helmet from "react-helmet"
import {useDispatch, useSelector} from "react-redux"

import {generateCancelToken} from "../../http"

import {
    receiveReportsData,
    receiveReportsSearch,
    receiveReportsTable,
    setSelectedUser
} from "./actions/reportsDataActions"

import Protected from "../../components/Protected/Protected"
import InfoCards from "./components/InfoCards"
import ReportTable from "./components/ReportTable"
import TrackerChart from "./components/TrackerChart"
import {Loading} from "../../components/Loading/Loading"

import {formatDate, mapDateRangeFilters} from "../../utils/datetime.utils"

import {getSavedReportsRange, getUserViewedAs} from "../../utils/localstorage.utils"

import SearchForm from "../../toolkits/SearchForm/SearchForm"
import SmallButton from "../../toolkits/SmallButton/SmallButton"
import CustomLink from "../../toolkits/CustomLink"

import {useCustomHistory} from "../../hooks/useCustomHistory"
import {TimeRecordsRoute, ReportsRoute, PaymentReportsRoute} from "../../routes"
import {receiveAllUserProjects} from "../Profile/actions/projectsActions"
import {PERMISSIONS} from "../../constants/permissions"
import Popup from "reactjs-popup"
import DayTimeRecordsPopup from "./components/DayTimeRecordsPopup"
import {useMediaQuery} from "react-responsive"
import {DESKTOP_WIDTH, FILTER_TYPES} from "../../constants/other"


const Reports = () => {    
    const history = useCustomHistory()
    const dispatch = useDispatch()

    const isMyReports = history.location?.pathname === ReportsRoute.path
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const [cancelToken, setCancelToken] = useState(null)
    const [tableCancelToken, setTableCancelToken] = useState(null)
    const [searchCancelToken, setSearchCancelToken] = useState(null)
    const [showDayTimeRecordsPopup, setShowDayTimeRecordsPopup] = useState(false)

    const auth = useSelector((state) => state.auth)
    const {hasPermission} = useSelector((state) => state.profile)
    
    const { users, hours_report, selectedUser } = useSelector(state => state.reportsPage)

    const projects = useSelector(state => state.profile.allProjects)

    const [selectedProjectIds, setSelectedProjectIds] = useState([])

    const savedReportsRange = getSavedReportsRange(!isMyReports)

    const [tableGroupBy, setTableGroupBy] = useState("project")
    const [searchKey, setSearchKey] = useState(
        selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : ""
    )
    const [isDisabledSearch, setIsDisabledSearch] = useState(!!selectedUser)
    const [date, setDate] = useState(Object.values(FILTER_TYPES).includes(savedReportsRange) ?
        mapDateRangeFilters(savedReportsRange) : mapDateRangeFilters(FILTER_TYPES.THIS_MONTH))
    const [chosenDate, setChosenDate] = useState(null)

    const [loading, setLoading] = useState(false)
    const refDate = useRef(date)
    const refSelectedProjectIds = useRef(selectedProjectIds)
    const refSelectedUser = useRef(selectedUser)
    const [refreshDataTrigger, setRefreshDataTrigger] = useState(false)
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : (isMyReports ? auth.user.uid : selectedUser?.id)

    const redirectTimeRecords = () => history.push(TimeRecordsRoute.path, {
        userId: userId
    })

    const refreshData = () => {
        return dispatch(
            receiveReportsData(
                userId,
                hasPermission(PERMISSIONS.READ_OWN_SALARY),
                {
                    start_date: formatDate(date.created_after),
                    end_date: formatDate(date.created_before),
                    ...(selectedProjectIds.length !== projects.length && { project_in: selectedProjectIds.toString() }),
                    group_by: tableGroupBy
                },
                generateCancelToken(cancelToken, setCancelToken)
            )
        )
    }

    useEffect(() => {
        if (isMyReports || selectedUser){
            dispatch(receiveAllUserProjects(userId, {ended_: true}))
        }
    }, [selectedUser])

    useEffect(() => {
        if (date || selectedUser || selectedProjectIds) {
            setRefreshDataTrigger(true)
        }
    }, [date, selectedUser, selectedProjectIds])

    // When filtering date is changed, refresh data without the loading screen and 
    // when the selected user changed, refresh the data with the Loading screen for at least 800ms
    useEffect(() => {
        if (refreshDataTrigger){
            if (refDate.current !== date){
                refreshData()
            }
            else if (refSelectedProjectIds.current !== selectedProjectIds) {
                refreshData()
            }
            else
            {
                if (selectedUser || isMyReports) {
                    setLoading(true)
                    refreshData().then(() => setTimeout(() => {
                        setLoading(false)
                    }, 800))
                }
            }
             
            refDate.current = date
            refSelectedUser.current = selectedUser
            refSelectedProjectIds.current = selectedProjectIds

            setRefreshDataTrigger(false)
        }
    }, [refreshDataTrigger])

    useEffect(() => {
        if (!isMyReports) {
            setSearchKey(selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : "")
        }
    }, [selectedUser])

    
    useEffect(() => {
        if (!isMyReports) {
            dispatch(
                receiveReportsSearch(searchKey, generateCancelToken(searchCancelToken, setSearchCancelToken))
            )
        }
    }, [searchKey])

    const handleReportsTable = (group_by) => {
        dispatch(
            receiveReportsTable(
                userId,
                {start_date: formatDate(date.created_after), end_date: formatDate(date.created_before), group_by,
                    ...(selectedProjectIds.length !== projects.length && { project_in: selectedProjectIds.toString() })},
                generateCancelToken(tableCancelToken, setTableCancelToken)
            )
        )
    }

    const usersList = users.map(user => 
        <div onClick={() => {
            dispatch(setSelectedUser(user))
            setSearchKey(user.first_name + " " + user.last_name)
            setIsDisabledSearch(true)
            history.location.state.user = user
        }} className="reports-users-item" key={user.id}>
            <img alt="Profile picture" src={user.image_url || UserImage} />
            <span className="t-s3">{user.first_name} {user.last_name}</span>
        </div>)
    
    
    if (loading) {
        return <Loading/>
    }

    return (
        <div>
            <Helmet>
                <title>Reports - Newsoft Inside</title>
            </Helmet>
            <div className="reports">
                <div className={`reports-header${!isMyReports ? "-with-search" : ""}`}>
                    {(hasPermission(PERMISSIONS.READ_REPORTS) && !isMyReports) &&
                    <>
                        <SearchForm 
                            className="reports-search"
                            value={searchKey}
                            onChange={(e) => !isDisabledSearch && setSearchKey(e.target.value)}
                            onClick={() => {
                                setSearchKey("")
                                setIsDisabledSearch(false)
                                dispatch(setSelectedUser(null))
                                history.location.state.user = null
                            }}
                            user={isDisabledSearch}
                        />
                        {(!isDisabledSearch && searchKey && usersList.length > 0) &&
                            <div className="reports-users">
                                {usersList}
                            </div>
                        }
                    </>
                    }
                    {!isMyReports &&
                        <Protected permissions={[PERMISSIONS.READ_PAYMENT_REPORTS]}>
                            <CustomLink to={{
                                pathname: PaymentReportsRoute.path,
                                state: {
                                    userId: userId
                                }
                            }}>
                                <SmallButton>payment reports</SmallButton>
                            </CustomLink>
                        </Protected>
                    }
                </div>
                {(selectedUser || isMyReports) ?
                    <>
                        <TrackerChart 
                            isEmptyChart={hours_report.current === "00:00"}
                            date={date}
                            setDate={setDate}
                            defaultDate={date}
                            user={!isMyReports}
                            userId={userId}
                            projects={projects}
                            selectedProjectIds={selectedProjectIds}
                            setSelectedProjectIds={setSelectedProjectIds}
                            setShowDayTimeRecordsPopup={setShowDayTimeRecordsPopup}
                            setChosenDate={setChosenDate}
                        />
                        <div className="reports-bottom">
                            <ReportTable
                                groupBy={tableGroupBy}
                                setGroupBy={setTableGroupBy}
                                handleReportsTable={handleReportsTable}
                                redirectTimeRecords={redirectTimeRecords}
                                isMyReports={isMyReports}
                            />
                            <InfoCards date={date} projects={(selectedProjectIds.length !== projects.length && 
                                (selectedProjectIds.length > 0 ? { project_in: selectedProjectIds.toString()} : {}))}/>
                        </div>

                    </> : 
                    <div className="reports-user-not-selected">
                        <p className="t-h1">User not selected</p>
                    </div>
                }
            </div>
            <Popup open={showDayTimeRecordsPopup && isDesktop} onClose={() => setShowDayTimeRecordsPopup(false)} modal>
                {close => (
                    <DayTimeRecordsPopup
                        close={close}
                        userId={userId}
                        date={chosenDate}
                    />)
                }
            </Popup>
        </div>

    )
}
 
export default Reports
