import React, {useContext, useState} from "react"
import Protected from "../../../../components/Protected/Protected"
import {PERMISSIONS} from "../../../../constants/permissions"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import Popup from "reactjs-popup"
import EditEquipmentPopup from "../Popups/EditEquipmentPopup"
import {receiveUserEquipment} from "../../actions/equipmentActions"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"

const EquipmentsCard = ({equipments, user, setUserMessage}) => {
    const [showEditEquipmentPopup, setShowEditEquipmentPopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div className={`profile-right-equipment ${hasPermission(PERMISSIONS.READ_EQUIPMENT) ? theme.profileCardHover : ""}`}
                onClick={() => hasPermission(PERMISSIONS.READ_EQUIPMENT) && setShowEditEquipmentPopup(true)}>
                <span className="t-h3 profile-font-leading">EQUIPMENT</span>

                <div className="profile-info-card-links">
                    <Protected permissions={[PERMISSIONS.READ_EQUIPMENT]}>
                        <Protected permissions={[PERMISSIONS.WRITE_EQUIPMENT]}>
                            <PenIcon onClick={() => setShowEditEquipmentPopup(true)} className="profile-info-card-pen-icon clickable"/>
                        </Protected>
                    </Protected>
                </div>

                {equipments.length > 0 ?
                    <div className="profile-equipment-content">
                        {equipments.map(eq => (
                            eq.equipment.map(e => (
                                <div className="profile-equipment-box" key={e.id}>
                                    <span className="t-b1 profile-equipment-title">{e.name}</span>
                                    <span className="t-b4 profile-equipment-serial-num">{e.serial_number}</span>
                                </div>
                            ))
                        ))}
                    </div> : <EmptyCard>No equipment</EmptyCard>
                }
            </div>


            <Popup open={showEditEquipmentPopup} closeOnDocumentClick={false} onClose={() => setShowEditEquipmentPopup(false)} modal>
                {close =>
                    <EditEquipmentPopup
                        user={user}
                        userEquipment={equipments}
                        receiveUserEquipment={receiveUserEquipment}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>
        </>
    )
}

export default EquipmentsCard