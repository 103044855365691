import {API_V1_URL} from "../../constants/other"
import http from "../../http"

const PROJECTS_URL = `${API_V1_URL}/projects/`


export const getAllProjects = (params) => http.get(PROJECTS_URL, {params})

export const createProject = (data) => http.post(PROJECTS_URL, {data})

export const updateProjectById = (projectId, data) => http.put(`${PROJECTS_URL}${projectId}`, {data})

export const deleteProjectById = (projectId) => http.delete(`${PROJECTS_URL}${projectId}`)

export const assignProject = (data) => http.post(`${PROJECTS_URL}assign`, {data})

export const unassignProjectFromMultipleUsers = (data) => http.post(`${PROJECTS_URL}unassign-multiple`, {data})
