import {getUserTimeRecords, getTimeRecords} from "../api"

import {
    GET_USER_TIME_RECORDS_REQUEST,
    GET_USER_TIME_RECORDS_SUCCESS,
    GET_USER_TIME_RECORDS_FAILURE,
    GET_NEXT_PAGE_USER_TIME_RECORDS_REQUEST,
} from "../const"


const request = () => ({type: GET_USER_TIME_RECORDS_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_USER_TIME_RECORDS_REQUEST})

const success = (timeRecords) => ({
    type: GET_USER_TIME_RECORDS_SUCCESS,
    payload: {timeRecords},
})

const failure = () => ({type: GET_USER_TIME_RECORDS_FAILURE})


export const receiveTimeRecords = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: timeRecordsData} = await getTimeRecords(params)
        dispatch(success(timeRecordsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageTimeRecords = (userId, params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: timeRecordsData} = await getTimeRecords(params)
        dispatch(success(timeRecordsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}


export const receiveUserTimeRecords = (userId, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: timeRecordsData} = await getUserTimeRecords(userId, params)    
        dispatch(success(timeRecordsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUserTimeRecords = (userId, params) => async (dispatch) => {
    dispatch(nextPageRequest())
    try {
        const {data: timeRecordsData} = await getUserTimeRecords(userId, params)    
        dispatch(success(timeRecordsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
