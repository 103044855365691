import {
    getTimeOffsUsageInfo,
    getTimeOffBalances,
    getTimeOffBalancesUniqueYears
} from "../api"
import {
    GET_TIME_OFF_USAGE_INFO_SUCCESS,
    GET_NEXT_PAGE_TIME_OFF_BALANCES_REQUEST,
    GET_TIME_OFF_BALANCES_FAILURE,
    GET_TIME_OFF_BALANCES_REQUEST,
    GET_TIME_OFF_BALANCES_SUCCESS,
    GET_TIME_OFF_USAGE_INFO_REQUEST,
    GET_TIME_OFF_USAGE_INFO_FAILURE,
    GET_TIME_OFF_BALANCES_UNIQUE_YEARS_REQUEST,
    GET_TIME_OFF_BALANCES_UNIQUE_YEARS_SUCCESS,
    GET_TIME_OFF_BALANCES_UNIQUE_YEARS_FAILURE,
} from "../const"


const request = () => ({type: GET_NEXT_PAGE_TIME_OFF_BALANCES_REQUEST})

const success = (timeOffBalances) => ({
    type: GET_TIME_OFF_BALANCES_SUCCESS,
    payload: {timeOffBalances},
})
const failure = () => ({type: GET_TIME_OFF_BALANCES_FAILURE})

const searchRequest = () => ({type: GET_TIME_OFF_BALANCES_REQUEST})

const successTimeOffsUsageInfo = (timeOffsUsageInfo) => ({
    type: GET_TIME_OFF_USAGE_INFO_SUCCESS,
    payload: {timeOffsUsageInfo},
})

const timeOffRequest = () => ({type: GET_TIME_OFF_USAGE_INFO_REQUEST})
const timeOffSFailure = () => ({type: GET_TIME_OFF_USAGE_INFO_FAILURE})

const requestYears = () => ({type: GET_TIME_OFF_BALANCES_UNIQUE_YEARS_REQUEST})

const successYears = (uniqueYears) => ({
    type: GET_TIME_OFF_BALANCES_UNIQUE_YEARS_SUCCESS,
    payload: {uniqueYears},
})

const failureYears = () => ({type: GET_TIME_OFF_BALANCES_UNIQUE_YEARS_FAILURE})


export const receiveNextPageTimeOffBalances = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveTimeOffBalancesData} = await getTimeOffBalances(params)

        dispatch(success(receiveTimeOffBalancesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveTimeOffBalances = ({params, cancelToken}) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveTimeOffBalancesData} = await getTimeOffBalances(params, cancelToken)
        
        dispatch(success(receiveTimeOffBalancesData))
    } catch (e) {
        dispatch(failure(e))
    }
}

export const receiveTimeOffsUsageInfo = (user_id, params) => async (dispatch) => {
    dispatch(timeOffRequest())
    try {
        const {data: timeOffsUsageData} = await getTimeOffsUsageInfo(user_id, params)
        dispatch(successTimeOffsUsageInfo(timeOffsUsageData))
    } catch (e) {
        dispatch(timeOffSFailure())
        console.log(e)
    }
}

export const receiveTimeOffBalancesUniqueYears = () => async (dispatch) => {
    dispatch(requestYears())
    try {
        const {data: receiveUniqueYears} = await getTimeOffBalancesUniqueYears()
        dispatch(successYears(receiveUniqueYears))
    } catch (e) {
        console.log(e)
        dispatch(failureYears())
    }
}
