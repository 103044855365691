import "./CropImagePopup.scss"

import React, {useState} from "react"
import Cropper from "react-easy-crop"

import Slider from "@material-ui/core/Slider"

import {updateProfileImage} from "../../api"

import {Loading} from "../../../../components/Loading/Loading"

import CustomPopup from "../../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../../toolkits/SmallButton/SmallButton"
import getCroppedImg from "../../../../utils/image.utils"
import { ERROR_CODE } from "../../../../constants/other"
import ClosePopupIcon from "../../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"

export const CropImagePopup = ({user_id, receiveUserInfo, image, setUserMessage, ...props}) => {
    const [imageProps, setImageProps] = useState({
        imageSrc: image,
        crop: {x: 0, y: 0},
        zoom: 1,
        aspect: 1,
    })
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [loading, setLoading] = useState(false)


    const onCropChange = (crop) => {
        setImageProps({...imageProps, crop})
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const onZoomChange = (zoom) => {
        setImageProps({...imageProps, zoom})
    }

    const saveImage = async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageProps.imageSrc,
                croppedAreaPixels,
            )
            setLoading(true)
            updateProfileImage(user_id, croppedImage).then(() => {
                receiveUserInfo()
                window.location.reload()
            })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        loading ? <Loading/> :
            <CustomPopup text_title={"Upload Photo"}>
                <div className='cropper-container'>
                    <Cropper
                        image={imageProps.imageSrc}
                        crop={imageProps.crop}
                        zoom={imageProps.zoom}
                        aspect={imageProps.aspect}
                        cropShape="rect"
                        showGrid={false}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={onZoomChange}
                    />
                </div>
                <div className="controls">
                    <RemoveCircleIcon fontSize="small" />
                    <Slider
                        value={imageProps.zoom}
                        min={1}
                        max={2.5}
                        step={0.01}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => onZoomChange(zoom)}
                    />
                    <AddCircleIcon fontSize="small"/>
                </div>

                <div className="popup-bottom">
                    <div className="popup-bottom-button-container">
                        <label className="small-button--secondary t-h3">
                            <input type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const file = e.target.files[0]
                                    if (file.type.startsWith("image/")){
                                        setImageProps({...imageProps, imageSrc: URL.createObjectURL(file)})
                                    } else {
                                        setUserMessage({
                                            message: "File format isn't accepted",
                                            code: ERROR_CODE
                                        })
                                    }
                                }}>
                            </input>
                            Upload
                        </label>
                        <SmallButton onClick={saveImage}>Save</SmallButton>
                    </div>
                </div>
                <ClosePopupIcon onClick={props.close}/>
            </CustomPopup>
    )
}
