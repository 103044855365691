import React from "react"
import {useDispatch} from "react-redux"

import {updateTimeOffRequestsById} from "../api"
import {receiveNotifications} from "../../Notifications/actions/dashboardNotificationsActions"

import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const ApproveTimeOffRequestPopup = ({time_off_request, approve, refreshTable, setUserMessage, close}) => {
    const fullName = `${time_off_request.user.first_name} ${time_off_request.user.last_name}`
    const dispatch = useDispatch()

    const handleSubmit = () => {
        const data = {
            start_date: time_off_request.start_date,
            approved: approve,
        }

        updateTimeOffRequestsById(time_off_request.id, data)
            .then(() => {
                refreshTable()
                dispatch(receiveNotifications())
                setUserMessage({message: `Time off request was successfully ${approve ? "approved" : "declined"}!`, code: SUCCESS_CODE})
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title={`Do you want to ${approve ? "approve" : "decline"} ${fullName} time off request?`}
            isSubtitle isAgreePopup
        >
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> {approve ? "APPROVE" : "DECLINE"} </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default ApproveTimeOffRequestPopup
