import "../../layouts/Navs/NavsLayout.scss"
import "./index.scss"

import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Helmet} from "react-helmet"

import moment from "moment"

import {receiveTimeTrackerDetails} from "./actions/timeTrackerDetailsActions"
import {receiveCalendarData} from "./actions/calendarDataActions"
import {receiveTimeRecords, receiveTimeOffRecords} from "./actions/timeRecordsActions"

import TimeRecords from "./components/TimeRecords"
import InfoCards from "./components/InfoCards"
import {Loading} from "../../components/Loading/Loading"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"

import {endOfMonth, formatDate, startOfMonth} from "../../utils/datetime.utils"

import {DAYS_PER_WEEK, TIME_RECORD_STATUS, WORKING_HOURS_STRING} from "../../constants/other"
import {getUserViewedAs} from "../../utils/localstorage.utils"


const TimeTracker = () => {
    const dispatch = useDispatch()

    const {
        day,
        week,
        month,
        overtimes,
        unpaid_day_off,
    } = useSelector((state) => state.timeTrackerDetails)
    const timeRecords = useSelector((state) => state.timeRecords.items)
    const timeOffRequests = useSelector((state) => state.timeRecords.timeOffRecords)
    const loadingTable = useSelector((state) => state.timeRecords.loading)
    const [timeOffTabActive, setTimeOffTabActive] = useState(false)
    const user = useSelector((state) => state.profile.myInfo)
    const auth = useSelector((state) => state.auth)

    const [loading, setLoading] = useState(true)
    const [userMessage, setUserMessage] = useState(null)
    const statuses = [TIME_RECORD_STATUS.APPROVED, TIME_RECORD_STATUS.IN_REVIEW, TIME_RECORD_STATUS.LOGGED].join(",")
    const userId = getUserViewedAs() ? getUserViewedAs().user_id : auth.user.uid

    const updateTimeTrackerForDay = (date) => {
        dispatch(receiveTimeRecords(userId, {created_at: formatDate(date),
            status_in: statuses, hours_type_in: WORKING_HOURS_STRING}))
        dispatch(receiveTimeTrackerDetails(userId, {date_: formatDate(date)}))
    }

    const updateTimeTrackerData = (date=new Date()) => {
        const startDate = moment(startOfMonth(date)).add(-DAYS_PER_WEEK, "days")
        const endDate = moment(endOfMonth(date)).add(DAYS_PER_WEEK, "days")

        Promise.all([
            dispatch(receiveTimeRecords(userId, {created_at: formatDate(date),
                status_in: statuses, hours_type_in: WORKING_HOURS_STRING})),
            dispatch(receiveTimeOffRecords(userId, {year: date.getFullYear(), declined: true})),
            dispatch(receiveTimeTrackerDetails(userId, {date_: formatDate(date)})),
            dispatch(receiveCalendarData(userId, {
                start_date: formatDate(startDate),
                end_date: formatDate(endDate),
            }))
        ])
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (auth.user) {
            updateTimeTrackerData(new Date())
        }
    }, [auth.user?.uid])

    return loading ? <Loading/> : (
        <div>
            <Helmet>
                <title>TimeTracker - Newsoft Inside</title>
            </Helmet>
            <div className="time-tracker">
                <div>
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
                </div>
                <div className="time-tracker-content t-b3">
                    <TimeRecords
                        setUserMessage={setUserMessage}
                        updateTimeTrackerForDay={updateTimeTrackerForDay}
                        updateTimeTrackerData={updateTimeTrackerData}
                        loadingTable={loadingTable}
                        timeRecords={timeRecords}
                        timeOffRequests={timeOffRequests}
                        userId={userId}
                        timeOffTabActive={timeOffTabActive}
                        setTimeOffTabActive={setTimeOffTabActive}
                    />
                    <InfoCards
                        day={day}
                        week={week}
                        month={month}
                        overtimes={overtimes}
                        unpaid_day_off={unpaid_day_off}
                        user={user}
                        timeOffTabActive={timeOffTabActive}
                    />
                </div>
            </div>
        </div>
    )
}


export default TimeTracker

