import {isAdmin} from "./permission.utils"

import {
    CACHED_IMAGES,
    DAY_TYPE,
    DEFAULT_COLOR_PICKER_COLOR,
    HOURS_TYPE,
    HOURS_TYPE_COLORS,
    PRODUCT_OPTION_VALUE_DIVIDER
} from "../constants/other"
import {DashboardRoute, ProfileRoute, routes} from "../routes"


export const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time))
}

export const getTitleOfPage = (url) => {
    if (url === DashboardRoute.path && isAdmin()) {
        return "Admin"
    }
    const result = routes.find((route) => route.path === url)
    if (result) {
        return result.name
    }
    if (url.startsWith("/profile/")) {
        return ProfileRoute.name
    }
}

export const isTimeOffHoursType = (hoursType) => {
    return hoursType === HOURS_TYPE.DayOff || hoursType === HOURS_TYPE.UnpaidDayOff || hoursType === HOURS_TYPE.Illness
}

export const extractSettingsByName = (settings, name) => {
    return settings.find((x) => x.name === name)
}

export const cacheImages = async (setLoading) => {
    const promises = Object.values(CACHED_IMAGES).map((src) => {
        return new Promise((resolve, reject) => {
            const img = new Image()

            img.src = src
            img.onload = resolve()
            img.onerror = reject()
        })
    })

    await Promise.all(promises)
    setLoading(false)
}

export const isNotZero = (value) => {
    return value > 0 ? value : null
}

export const isReviewComing = (date, upcomingPerformanceReview) => {
    const newDate = new Date(date)
    const today = new Date()

    today.setHours(0, 0, 0, 0)
    newDate.setHours(0, 0, 0, 0)

    if (upcomingPerformanceReview) {
        const lastComingDay = today.setDate(today.getDate() + parseInt(upcomingPerformanceReview.value))
        return newDate < lastComingDay
    }
    return false
}


export const range = (from, to, step) => [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step)


export const capitalizeFirstLetter = (string) => {
    return string[0].toUpperCase() + string.slice(1)
}

export const roundEarn = (value) => {
    const roundedValue = Math.round(value * 100) / 100
    return Number.isInteger(roundedValue) ? roundedValue.toFixed(0) : roundedValue.toFixed(2)
}

export const nthNumberSuffix = (num) => {
    const defaultOption = "th"
    const options = {1: "st", 2: "nd", 3: "rd"}

    return (num > 3 && num < 21) ? defaultOption
        : (num % 10) in options ? options[num % 10] : defaultOption
}

export const reformatPhoneNumber = (phone) => {
    return phone.replace(/[^\d+/]/g,"")
}

export const getColorByHoursType = (type, approved) => {
    let adjusted_type = approved !== null ? type : "Requested " + type
    adjusted_type = approved === false ? "Declined " + type : adjusted_type
    const matchingType = HOURS_TYPE_COLORS.find(entry => entry.type === adjusted_type)
    return matchingType ? matchingType.color : "#F57F17"
}

export const blockBackground = () => {
    const body = document.body
    const scrollableTable = document.getElementsByClassName("custom-scrollable-table")

    body.classList.add("popup-active-block-background")
    scrollableTable.length
    && scrollableTable[0].classList.add("popup-active-block-background")

    return () => {
        setTimeout(() => {
            body.classList.remove("popup-active-block-background")
            scrollableTable.length &&
            scrollableTable[0].classList.remove("popup-active-block-background")
        }, 300)
    }
}

export const getLoggedClass = (type) => {
    switch (type) {
    case DAY_TYPE.LOGGED:
        return "rmdp-logged"
    case DAY_TYPE.PARTIALLY_LOGGED:
        return "rmdp-partially-logged"
    default:
        return ""
    }
}

export function displayHTML(text) {
    if (/<[a-z][\s\S]*>/i.test(text)) {
        return { __html: text }
    } else if (text.split("\n").length > 0) {
        const paragraphs = text.split("\n").map((paragraph, index) => (
            `<p key=${index}>${paragraph}</p>`
        ))
        return { __html: paragraphs.join("") }
    } else return { __html: text }
}

export function toCamelCase(str) {
    return str.replace(/(_\w)/g, match => match[1].toUpperCase())
}

export function isTrue(value) {
    return value.toLowerCase() === "true"
}

export function getColorOptionValues(value) {
    const [text, color] = value ? value.split(PRODUCT_OPTION_VALUE_DIVIDER) : [value, DEFAULT_COLOR_PICKER_COLOR]
    return {text: text, color: color}
}