export const GET_REPORTS_TABLE_REQUEST = "GET_REPORTS_TABLE_REQUEST"
export const GET_REPORTS_TABLE_SUCCESS = "GET_REPORTS_TABLE_SUCCESS"
export const GET_REPORTS_TABLE_FAILURE = "GET_REPORTS_TABLE_FAILURE"

export const GET_REPORTS_DATA_REQUEST = "GET_REPORTS_DATA_REQUEST"
export const GET_REPORTS_DATA_SUCCESS = "GET_REPORTS_DATA_SUCCESS"
export const GET_REPORTS_DATA_FAILURE = "GET_REPORTS_DATA_FAILURE"

export const GET_USERS_REPORTS = "GET_USERS_REPORTS"
export const SET_SELECTED_USER = "SET_SELECTED_USER"

export const SET_SELECTED_PROJECTS = "SET_SELECTED_PROJECTS"


export const GET_SALARY_EARN_REQUEST = "GET_SALARY_EARN_REQUEST"

export const GET_SALARY_EARN_SUCCESS = "GET_SALARY_EARN_SUCCESS"

export const GET_SALARY_EARN_FAILURE = "GET_SALARY_EARN_FAILURE"

export const GET_OVERTIME_EARN_REQUEST = "GET_OVERTIME_EARN_REQUEST"

export const GET_OVERTIME_EARN_SUCCESS = "GET_OVERTIME_EARN_SUCCESS"

export const GET_OVERTIME_EARN_FAILURE = "GET_OVERTIME_EARN_FAILURE"


export const GET_BONUS_EARN_REQUEST = "GET_BONUS_EARN_REQUEST"

export const GET_BONUS_EARN_SUCCESS = "GET_BONUS_EARN_SUCCESS"

export const GET_BONUS_EARN_FAILURE = "GET_BONUS_EARN_FAILURE"

export const GET_DAY_TIME_RECORDS_REQUEST = "GET_DAY_TIME_RECORDS_REQUEST"

export const GET_DAY_TIME_RECORDS_SUCCESS = "GET_DAY_TIME_RECORDS_SUCCESS"

export const GET_DAY_TIME_RECORDS_FAILURE = "GET_DAY_TIME_RECORDS_FAILURE"
