import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const TIME_OFFS_URL = `${API_V1_URL}/time-off-requests/`
const USERS_URL = `${API_V1_URL}/users/`


export const getTimeOffRequests = (params) => http.get(`${TIME_OFFS_URL}`, {params})

export const getUserTimeOffRequests = (userId, params) => http.get(`${USERS_URL}${userId}/time-off-requests`, {params})

export const updateTimeOffRequestsById = (timeOffRequestId, data) =>
    http.put(`${TIME_OFFS_URL}${timeOffRequestId}`, {data})

export const createTimeOffRequest = (payload) => http.post(TIME_OFFS_URL, {data: payload})

export const deleteTimeOffRequestById = (timeOffRequestId) => http.delete(`${TIME_OFFS_URL}${timeOffRequestId}`)
