import "./index.scss"

import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import Popup from "reactjs-popup"
import {useDispatch, useSelector} from "react-redux"

import {refreshTimeOffBalances, updateSettingById} from "./api"
import {receiveSettings} from "./actions/settingsActions"
import {receiveNotifications} from "../Notifications/actions/dashboardNotificationsActions"

import Protected from "../../components/Protected/Protected"
import {Loading} from "../../components/Loading/Loading"
import SettingsCard from "./components/SettingsCard"
import NumberEditPopup from "../../components/NumberEditPopup"
import AgreePopup from "../../components/AgreePopup"
import CustomPopup from "../../toolkits/CustomPopup/CustomPopup"

import SmallButton from "../../toolkits/SmallButton/SmallButton"
import AlertMessage from "../../toolkits/AlertMessage/AlertMessage"
import DDList from "../../toolkits/DDList/DDList"

import {saveAuthorizedUserData} from "../../utils/localstorage.utils"

import {ERROR_CODE, SUCCESS_CODE, WEBSITE_THEME_OPTIONS} from "../../constants/other"
import {PERMISSIONS} from "../../constants/permissions"
import SettingsFeatureFlag from "./components/SettingsFeatureFlag"


const Settings = () => {
    const dispatch = useDispatch()

    const {
        accessToTimeTracker,
        defaultTimeOffsPerYear,
        maxTimeOffsPerYear,
        upcomingPerformanceReview,
        maxDaysStoreNotifications,
        accessToEditBonus,
        websiteTheme,
        newcoinsEnabled,
        shopEnabled
    } = useSelector((state) => state.settings)

    const [userMessage, setUserMessage] = useState(null)
    const [defaultDayOffsPerYearPopup, setDefaultDayOffsPerYearPopup] = useState(false)
    const [accessToTimeTrackerPopup, setAccessToTimeTrackerPopup] = useState(false)
    const [maxDayOffsPerYearPopup, setMaxDayOffsPerYearPopup] = useState(false)
    const [upcomingPerformanceReviewPopup, setUpcomingPerformanceReviewPopup] = useState(false)
    const [refreshDayOffsPopup, setRefreshDayOffsPopup] = useState(false)
    const [maxDaysStoreNotificationsPopup, setMaxDaysStoreNotificationsPopup] = useState(false)
    const [websiteThemePopup, setWebsiteThemePopup] = useState(false)
    const [maxPassedDaysToEditBonusPopup, setMaxPassedDaysToEditBonusPopup] = useState(false)

    const [theme, setTheme] = useState({value: websiteTheme.value, label: websiteTheme.value})


    const loading = () => !defaultTimeOffsPerYear.id || !maxTimeOffsPerYear || !upcomingPerformanceReview || !maxDaysStoreNotifications || !accessToEditBonus || !websiteTheme

    const updateGlobalSetting = ({setting, value, close, refresh, message}) => {
        updateSettingById({setting_id: setting.id, value: value})
            .then(() => {
                dispatch(receiveSettings())
                refresh && refresh()
                setUserMessage({message: message, code: SUCCESS_CODE})
                close && close()
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    const onRefreshTimeOffBalances = ({close}) => {
        refreshTimeOffBalances()
            .then(() => {
                setUserMessage({message: "Time Off Balance was successfully refreshed!", code: SUCCESS_CODE})
                close()
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    useEffect(() => {
        dispatch(receiveSettings())
    }, [])

    return (loading() ? <Loading /> :
        <>
            <Helmet>
                <title>Settings - Newsoft Inside</title>
            </Helmet>
            <div className="settings">
                <div className="settings-alert">
                    {userMessage &&
                        <AlertMessage setMessage={setUserMessage} message={userMessage.message} code={userMessage.code}/>}
                </div>

                <div className="settings-box">
                    <SettingsCard title="ACCESS TO TIME TRACKER" value={`${accessToTimeTracker.value} day${accessToTimeTracker.value == 1 ? "" : "s"}`}>
                        <SmallButton onClick={() => setAccessToTimeTrackerPopup(true)}> CHANGE </SmallButton>
                    </SettingsCard>

                    <SettingsCard title="DEFAULT YEAR TIME OFF" value={`${defaultTimeOffsPerYear.value} day${defaultTimeOffsPerYear.value == 1 ? "" : "s"}`}>
                        <SmallButton onClick={() => setDefaultDayOffsPerYearPopup(true)}> CHANGE </SmallButton>
                    </SettingsCard>

                    <SettingsCard title="MAX YEAR TIME OFF" value={`${maxTimeOffsPerYear.value} day${maxTimeOffsPerYear.value == 1 ? "" : "s"}`}>
                        <SmallButton onClick={() => setMaxDayOffsPerYearPopup(true)}> CHANGE </SmallButton>
                    </SettingsCard>

                    <SettingsCard title="UPCOMING PERFORMANCE REVIEW" value={`${upcomingPerformanceReview.value} day${upcomingPerformanceReview.value == 1 ? "" : "s"}`}>
                        <SmallButton onClick={() => setUpcomingPerformanceReviewPopup(true)}> CHANGE </SmallButton>
                    </SettingsCard>

                    <SettingsCard title="MAX DAYS TO STORE NOTIFICATIONS" value={`${maxDaysStoreNotifications.value} day${maxDaysStoreNotifications.value == 1 ? "" : "s"}`}>
                        <SmallButton onClick={() => setMaxDaysStoreNotificationsPopup(true)}> CHANGE </SmallButton>
                    </SettingsCard>

                    <Protected permissions={[PERMISSIONS.WRITE_TIME_OFF_BALANCE]}>
                        <SettingsCard title="REFRESH TIME OFF">
                            <SmallButton onClick={() => setRefreshDayOffsPopup(true)}> REFRESH </SmallButton>
                        </SettingsCard>
                    </Protected>

                    <SettingsCard title="INSIDE THEME" value={websiteTheme.value} subtitle={true}>
                        <SmallButton onClick={() => setWebsiteThemePopup(true)}> CHANGE THEME </SmallButton>
                    </SettingsCard>

                    <SettingsCard title="MAX DAYS TO EDIT BONUS " value={`${accessToEditBonus.value} day${accessToEditBonus.value == 1 ? "" : "s"}`}>
                        <SmallButton onClick={() => setMaxPassedDaysToEditBonusPopup(true)}> CHANGE </SmallButton>
                    </SettingsCard>

                </div>
                <div className="settings-column">
                    <SettingsFeatureFlag title="NEWCOINS" setting={newcoinsEnabled} updateGlobalSetting={updateGlobalSetting} />

                    <SettingsFeatureFlag title="SHOP" setting={shopEnabled} updateGlobalSetting={updateGlobalSetting} />
                </div>

                <Popup open={accessToTimeTrackerPopup} closeOnDocumentClick onClose={() => setAccessToTimeTrackerPopup(false)} modal>
                    {close => (
                        <NumberEditPopup
                            title="Access to time tracker"
                            label="Days"
                            defaultValue={accessToTimeTracker.value}
                            onSave={(value) => updateGlobalSetting({
                                value: value,
                                setting: accessToTimeTracker,
                                message: "'ACCESS TO TIME TRACKER' was successfully updated!",
                                refresh: () => saveAuthorizedUserData({
                                    accessToTimeTracker: {...accessToTimeTracker, value,}
                                }),
                                close: close,
                            })}
                            min={1}
                            max={50}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={defaultDayOffsPerYearPopup} closeOnDocumentClick onClose={() => setDefaultDayOffsPerYearPopup(false)} modal>
                    {close => (
                        <NumberEditPopup
                            title="Default year timeoffs"
                            label="Days"
                            defaultValue={defaultTimeOffsPerYear.value}
                            onSave={(value) => updateGlobalSetting({
                                value: value,
                                setting: defaultTimeOffsPerYear,
                                message: "'DEFAULT YEAR TIMEOFFS' was successfully updated!",
                                close: close,
                            })}
                            min={10}
                            max={maxTimeOffsPerYear.value}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={maxDayOffsPerYearPopup} closeOnDocumentClick onClose={() => setMaxDayOffsPerYearPopup(false)} modal>
                    {close => (
                        <NumberEditPopup
                            title="Max year time off"
                            label="Days"
                            defaultValue={maxTimeOffsPerYear.value}
                            onSave={(value) => updateGlobalSetting({
                                value: value,
                                setting: maxTimeOffsPerYear,
                                message: "'MAX YEAR TIMES OFF' was successfully updated!",
                                close: close,
                            })}
                            min={defaultTimeOffsPerYear.value}
                            max={35}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={upcomingPerformanceReviewPopup} closeOnDocumentClick onClose={() => setUpcomingPerformanceReviewPopup(false)} modal>
                    {close => (
                        <NumberEditPopup
                            title="Upcoming performance review"
                            label="Days"
                            defaultValue={upcomingPerformanceReview.value}
                            onSave={(value) => updateGlobalSetting({
                                value: value,
                                setting: upcomingPerformanceReview,
                                message: "'UPCOMING PERFORMANCE REVIEW' was successfully updated!",
                                refresh: () => dispatch(receiveNotifications()),
                                close: close,
                            })}
                            min={1}
                            max={30}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={maxDaysStoreNotificationsPopup} closeOnDocumentClick onClose={() => setMaxDaysStoreNotificationsPopup(false)} modal>
                    {close => (
                        <NumberEditPopup
                            title="Max days to store notifications"
                            label="Days"
                            defaultValue={maxDaysStoreNotifications.value}
                            onSave={(value) => updateGlobalSetting({
                                value: value,
                                setting: maxDaysStoreNotifications,
                                message: "'MAX DAYS TO STORE NOTIFICATIONS' was successfully updated!",
                                refresh: () => dispatch(receiveNotifications()),
                                close: close,
                            })}
                            min={1}
                            max={365}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={websiteThemePopup} closeOnDocumentClick onClose={() => setWebsiteThemePopup(false)} modal>
                    {close => (
                        <CustomPopup text_title="Change theme">
                            <div className="popup-content">
                                <DDList
                                    isSearchable={false}
                                    text_label="Theme"
                                    onChange={(value) => setTheme(value)}
                                    listOptions={WEBSITE_THEME_OPTIONS}
                                    value={theme}
                                />
                            </div>
                            <div className="popup-bottom">
                                <div className="popup-bottom-button-container">
                                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                                    <SmallButton type="submit" onClick={() => updateGlobalSetting({
                                        value: theme?.value,
                                        setting: websiteTheme,
                                        message: "'WEBSITE THEME' was successfully updated!",
                                        close: close,
                                    })}> CHANGE </SmallButton>
                                </div>
                            </div>
                        </CustomPopup>
                    )}
                </Popup>

                <Popup open={refreshDayOffsPopup} closeOnDocumentClick onClose={() => setRefreshDayOffsPopup(false)} modal>
                    {close => (
                        <AgreePopup
                            title="Do you really want to refresh all users day offs?"
                            buttonText="REFRESH"
                            onSave={() => onRefreshTimeOffBalances({close})}
                            close={close}
                        />
                    )}
                </Popup>

                <Popup open={maxPassedDaysToEditBonusPopup} closeOnDocumentClick onClose={() => setMaxPassedDaysToEditBonusPopup(false)} modal>
                    {close => (
                        <NumberEditPopup
                            title="Max days to edit bonus"
                            label="Days"
                            defaultValue={accessToEditBonus.value}
                            onSave={(value) => updateGlobalSetting({
                                value: value,
                                setting: accessToEditBonus,
                                message: "'MAX DAYS TO EDIT BONUS' was successfully updated!",
                                refresh: () => dispatch(receiveNotifications()),
                                close: close,
                            })}
                            min={1}
                            max={365}
                            close={close}
                        />
                    )}
                </Popup>

            </div>
        </>
    )
}

export default Settings
