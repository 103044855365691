import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const TIME_OFF_BALANCE_URL = `${API_V1_URL}/time-off-balance/`

export const getTimeOffBalances = (params, cancelToken) => http.get(TIME_OFF_BALANCE_URL, {params}, {}, cancelToken)

export const getTimeOffBalancesUniqueYears = () => http.get(`${TIME_OFF_BALANCE_URL}years`)

export const getTimeOffsUsageInfo = (user_id, params) => http.get(`${TIME_OFF_BALANCE_URL}time-offs-usage-info/${user_id}`, {params})
