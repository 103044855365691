import {
    GET_TIME_OFF_REQUESTS_REQUEST,
    GET_TIME_OFF_REQUESTS_SUCCESS,
    GET_TIME_OFF_REQUESTS_FAILURE,
    GET_NEXT_PAGE_TIME_OFF_REQUESTS_REQUEST,
} from "../const"
    

const initialState = {
    timeOffRequests: [],
    total: 0,
    tableLoading: false,
}
  
const handlers = {
    [GET_TIME_OFF_REQUESTS_REQUEST]: (state) => ({ ...state, tableLoading: true, timeOffRequests: [], total: 0 }),
    [GET_TIME_OFF_REQUESTS_SUCCESS]: (state, { payload: {timeOffRequests} }) => ({
        ...state,
        timeOffRequests: [...state.timeOffRequests, ...timeOffRequests.items],
        total: timeOffRequests.total,
        tableLoading: false,
    }),
    [GET_TIME_OFF_REQUESTS_FAILURE]: (state) => ({ ...state, tableLoading: false }),

    [GET_NEXT_PAGE_TIME_OFF_REQUESTS_REQUEST]: (state) => ({ ...state }),

    DEFAULT: (state) => state,
}
 

const timeOffRequestsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default timeOffRequestsReducer
  