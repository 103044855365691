import SettingsEnabled from "../../../../components/SettingsEnabled/SettingsEnabled"
import {SETTINGS} from "../../../../constants/other"
import {PERMISSIONS} from "../../../../constants/permissions"
import InfoIcon from "@mui/icons-material/Pending"
import {SensitiveInfo} from "../../../../components/SensitiveInfo/SensitiveInfo"
import Newcoin from "../../../../toolkits/Newcoin"
import React, {useContext, useState} from "react"
import Popup from "reactjs-popup"
import NewcoinsUsagePopup from "../Popups/NewcoinsUsagePopup"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"
import PenIcon from "@mui/icons-material/Create"

const NewcoinsCard = ({newcoins, user}) => {
    const [showNewcoinsUsagePopup, setShowNewcoinsUsagePopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <SettingsEnabled settingsNames={[SETTINGS.NEWCOINS_ENABLED]}>
            <div onClick={() => setShowNewcoinsUsagePopup(true)} className={`profile-info-card ${hasPermission(PERMISSIONS.READ_NEWCOINS) ? `clickable  ${theme.profileCardHover}` : ""}`}>
                <div>
                    <h3 className="t-h3 profile-font-leading">NEWCOINS</h3>

                    <div className="profile-info-card-links">
                        {newcoins ?
                            <InfoIcon className="profile-info-card-improvements clickable"/>
                            : hasPermission(PERMISSIONS.READ_NEWCOINS) && <PenIcon className="profile-info-card-pen-icon clickable"/>
                        }
                    </div>

                    <h1 className="t-h1">
                        <SensitiveInfo className="sensitive-info">
                            <div className="profile-info-newcoins">
                                {newcoins} <Newcoin type={"active"}/>
                            </div>
                        </SensitiveInfo>
                    </h1>
                </div>
            </div>

            <Popup open={showNewcoinsUsagePopup} onClose={() => setShowNewcoinsUsagePopup(false)} modal>
                {close => (
                    <NewcoinsUsagePopup
                        close={close}
                        user={user}
                    />
                )}
            </Popup>
        </SettingsEnabled>
    )
}

export default NewcoinsCard