import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import {receiveTimeOffBalances, receiveTimeOffsUsageInfo} from "../actions/timeOffBalancesActions"


import StyledCircularProgress from "../../../toolkits/CircularProgress/CircularProgress"
import FlexiblePopup from "../../../toolkits/FlexiblePopup"

import ClosePopupIcon from "../../../toolkits/ClosePopupIcon/ClosePopupIcon"
import {getColorByHoursType} from "../../../utils/other.utils"
import Select from "react-select"
import {MOBILE_WIDTH, PAGE_SIZE} from "../../../constants/other"
import {useMediaQuery} from "react-responsive"


const TimeOffUsageInfoPopup = ({employee, year, close}) => {
    const dispatch = useDispatch()
    const timeOffUsageInfo = useSelector((state) => state.timeOffBalances.timeOffsUsageInfo)
    const totalDays = timeOffUsageInfo.reduce((total, record) => {
        return total + (record.approved === true ? Number(record.count) : 0)
    }, 0)
    const {popupLoading} = useSelector((state) => state.timeOffBalances)
    const isMobile = useMediaQuery({maxWidth: MOBILE_WIDTH})
    const [timeOffLeft, setTimeOffLeft] = useState(0)
    const timeOffBalances = useSelector((state) => state.timeOffBalances.timeOffBalances)

    const startYear = new Date(employee.hiring_date).getFullYear()
    const currentYear = new Date().getFullYear()
    const [yearOptions, setYearOptions] = useState([])
    const [selectedYear, setSelectedYear] = useState({ value: year, label: `${year}` })

    useEffect(() => {
        const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
            return { value: startYear + i, label: `${startYear + i}` }
        }).sort((a, b) => b.value - a.value)
        setYearOptions(years)
        setSelectedYear({ value: year, label: `${year}` })

        dispatch(receiveTimeOffsUsageInfo(employee.id, {year: year}))
    }, [dispatch, employee.id, year, startYear, currentYear])

    useEffect(() => {
        setTimeOffLeft(timeOffBalances[0]?.unused)
    }, [timeOffBalances])

    const handleYearChange = async (selectedOption) => {
        setSelectedYear(selectedOption)
        dispatch(receiveTimeOffsUsageInfo(employee.id, {year: selectedOption.value}))

        if (selectedOption.value < currentYear) {
            try {
                const params = {
                    size: PAGE_SIZE,
                    page: 1,
                    user_id: employee.id,
                    year: selectedOption.value,
                }
                dispatch(receiveTimeOffBalances({params}))
            } catch (error) {
                console.error("Error fetching time off balances:", error)
            }
        } else {
            setTimeOffLeft(0)
        }
    }

    return (
        <FlexiblePopup>
            <div className="time-offs-popup">
                <div className="time-offs-popup-title t-h2">Time Off Usage</div>
                <div className="time-offs-popup-left">
                    {selectedYear.value < currentYear ?
                        <span className={`time-offs-popup-left-info-inactive ${isMobile ? "t-s3" : "t-s1"}`}>TIME OFF LEFT: {timeOffLeft || 0} DAY{timeOffLeft != 1 ? "S": ""}</span>
                        :
                        <span className={`time-offs-popup-left-info${employee.time_off_left === 0 || selectedYear.value != currentYear ? "-inactive" : ""} ${isMobile ? "t-s3" : "t-s1"}`}>TIME OFF LEFT: {`${selectedYear.value != currentYear ? 0 : employee.time_off_left} DAY${employee.time_off_left != 1 ? "S": ""}`}</span>
                    }
                    <Select
                        isSearchable={false}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        options={yearOptions}
                        value={selectedYear}
                        onChange={handleYearChange}
                        classNamePrefix="custom-select t-s1 working-year-react-select"
                    />
                </div>
                <table className="time-offs-popup-table">
                    <thead className="time-offs-popup-table-head">
                        <tr className="time-offs-popup-table-row heading-color t-h3">
                            <th className="time-offs-popup-table-row-type">Type</th>
                            <th className="time-offs-popup-table-row-period">Period {isMobile && <span className="time-offs-popup-table-row-days-mobile">(Days)</span>}</th>
                            {!isMobile &&
                            <th className="time-offs-popup-table-row-days">Days</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {popupLoading ? <div className="time-offs-popup-loading"><StyledCircularProgress/></div>
                            :
                            <>
                                {timeOffUsageInfo && timeOffUsageInfo.map((record, i) => (
                                    (<>
                                        <hr className={` ${ i===0 ? "first" : ""} time-offs-popup-table-divider`}/>
                                        <tr key={i} className="time-offs-popup-table-row t-b2">
                                            <td className="time-offs-popup-table-row-type t-b2"><li style={{backgroundColor: getColorByHoursType(record.type, record.approved)}} className="dot"></li>{record.type}</td>
                                            <td className="time-offs-popup-table-row-period t-b2">{record.period} {isMobile && <span className="time-offs-popup-table-row-days-mobile">({record.count})</span>}</td>
                                            {!isMobile &&
                                            <td className="time-offs-popup-table-row-days t-s4">{record.count} {record.approved === null && <p className="time-offs-popup-in-review t-b3">in review</p>}</td>
                                            }
                                        </tr>

                                    </>)
                                ))}
                                <hr className="time-offs-popup-table-divider"/>
                                <tr className="time-offs-popup-table-row t-b1">
                                    <td className="time-offs-popup-table-row-type">Total:</td>
                                    <td className="time-offs-popup-table-row-period"></td>
                                    <td className="time-offs-popup-table-row-days">{totalDays}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
                <ClosePopupIcon className="newcoins-popup-close" onClick={close}/>
            </div>
        </FlexiblePopup>
    )
}

export default TimeOffUsageInfoPopup
