import React, {useEffect, useRef, useState} from "react"
import DatePicker, {Calendar} from "react-multi-date-picker"

import {useCalendar} from "../../hooks"

import {CustomButton} from "./CustomButton"

import {DD_MM_YYYY, DESKTOP_WIDTH} from "../../constants/other"
import InputIcon from "react-multi-date-picker/components/input_icon"
import SmallButton from "../SmallButton/SmallButton"
import {positionTooltip} from "../../utils/timeoff.utils"
import {useMediaQuery} from "react-responsive"


export const CustomRangeDatePickerHeader = ({
    state,
    isMobile,
    DatePicker,
    calendarTitle
}) => {
    const {selectedDate} = state
    const from = selectedDate[0]?.format?.("DD.MM")
    const to = (selectedDate[1] && selectedDate[0] !== selectedDate[1]) ? selectedDate[1].format?.("DD.MM") : ""

    return(
        <div className="range-datepicker-plugin">
            <div className="range-datepicker-plugin-type t-h3">
                {calendarTitle}
            </div>

            <div className="range-datepicker-plugin-range">
                <div>
                    <div className={`${isMobile ? "t-s4" : "t-h2 "}`}>{from}{to ? "-" : ""}</div>
                    <div className={`${isMobile ? "t-s4" : "t-h2 "}`}>{to }</div>
                </div>
            </div>
            {isMobile && (from || to) &&
            <SmallButton btnType="secondary" onClick={DatePicker.closeCalendar}>APPLY</SmallButton>}
        </div>
    )
}


export const RangeDatePicker = ({label, calendarTitle, calendarOnly, onChange, footerOnBottom, isMobile, ...props}) => {

    const {fillCalendar} = useCalendar()
    const tooltipRef = useRef(null)
    const [data, setData] = useState({})
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const commonProps = {
        mapDays: ({date, today, selectedDate, isSameDate}) =>
            fillCalendar(date, today, selectedDate, isSameDate, new Date(), true, true, false, data, setData, props.value, props.setUserMessage),
        range: true,
        format: DD_MM_YYYY,
        showOtherDays: true,
        onChange: onChange,
        onlyShowInRangeDates: true,
        weekStartDayIndex: 1,
        plugins: [
            <CustomRangeDatePickerHeader
                calendarTitle={calendarTitle}
                position={footerOnBottom ? "bottom" : "left"}
                isMobile={isMobile}
                key="range_date_picker_header"
            />
        ],
        ...props
    }

    const CustomRangeInput = ({openCalendar, name, value}) => {
        let from = value[0] || ""
        let to = value[1] || ""

        value = name ? `${from}-${to}` : (from && to && to !== from ? "From " + from + " to " + to : from)

        return name ? (
            <span onClick={() => {
                openCalendar()
            }} className={"t-s4"}>
                {value != "-" ? value: name}
            </span>
        ) :(
            <label className="range-date-input-wrapper-label">
                <InputIcon
                    value={value}
                    onClick={() => openCalendar()}
                    readOnly
                />
            </label>
        )
    }

    useEffect(() => {
        positionTooltip(tooltipRef, data)
    }, [data])

    return (
        <>
            {label && <p className="range-date-input-label t-s3">{label}</p>}
            <div className="range-date-input-wrapper">
                {calendarOnly ?
                    <Calendar
                        {...commonProps}
                        containerStyle={{ position: "relative" }}
                        renderButton={<CustomButton/>}
                    >
                        {isDesktop &&
                        <span
                            ref={tooltipRef}
                            style={{
                                visibility: data.visible ? "visible" : "hidden",
                            }}
                            className="calendar-tooltip"
                        >
                            {data.title}
                        </span>}
                    </Calendar>
                    :
                    <DatePicker
                        {...commonProps}
                        calendarPosition="bottom"
                        inputClass="calendar"
                        render={<CustomRangeInput/>}
                        renderButton={<CustomButton/>}
                    />
                }
            </div>
        </>
    )
}
