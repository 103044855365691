import http from "../../http"
import {API_V1_URL} from "../../constants/other"


const USERS_URL = `${API_V1_URL}/users/`
const TIME_RECORDS_URL = `${API_V1_URL}/time-records/`
const UTILS_URL = `${API_V1_URL}/utils/`
const PROJECTS_URL = `${API_V1_URL}/projects/`
const EQUIPMENT_URL = `${API_V1_URL}/equipment/`
const NEWCOINS_TRANSACTIONS = `${API_V1_URL}/newcoins-transactions/`

export const getMe = () => http.get(`${USERS_URL}me`)


export const getUserById = (userId) => http.get(`${USERS_URL}${userId}`)


export const getUserEquipment = (userId) => http.get(`${USERS_URL}${userId}/equipment`)


export const getTimeTrackerChart = (userId, params) =>
    http.get(`${TIME_RECORDS_URL}time-tracker-chart/${userId}/`, {params})


export const updateProfileImage = (userId, image) => {
    const formData = new FormData()
    formData.append("user_id", userId)
    formData.append("image", image)

    return http.post(`${UTILS_URL}upload-profile-image`, {data: formData})
}

export const getUserProjects = (userId, params) => http.get(`${USERS_URL}${userId}/projects`, {params})

export const getUserRoles = (userId) => http.get(`${USERS_URL}${userId}/roles`)

export const unassignProject = (payload) => http.post(`${PROJECTS_URL}unassign`, {data: payload})

export const unassignEquipment = (payload) => http.post(`${EQUIPMENT_URL}unassign`, {data: payload})

export const updateEnglishLevel = (userId, payload) => http.put(`${USERS_URL}${userId}`, {data: payload})

export const updateHiringDate = (userId, payload) => http.put(`${USERS_URL}${userId}`, {data: {...payload}})

export const updateUserRoles = (userId, data) => http.put(`${USERS_URL}${userId}/roles`, {data})

export const setUserLastDate = (userId, data) => http.post(`${USERS_URL}${userId}/set-last-date`, {data})

export const getUserNewcoins = (userId) => http.get(`${NEWCOINS_TRANSACTIONS}newcoins-balances/${userId}`)

export const getUserNewcoinsSpent = (userId) => http.get(`${NEWCOINS_TRANSACTIONS}newcoins-balances/${userId}/spent`)
