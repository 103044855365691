import React from "react"
import copyIcon from "../../assets/images/copyIcon.svg"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { INFO_ALERT, SUCCESS_CODE, MIN_ALERT_DURATION } from "../../constants/other"

const CopyButton = ({email, setUserMessage, type, children}) => {

    return (
        <CopyToClipboard text={email} onCopy={() => 
            setUserMessage({message: `${type} copied!`, code: SUCCESS_CODE, type: INFO_ALERT, duration: MIN_ALERT_DURATION})}>
            {children || <img className="copy-icon clickable" src={copyIcon} alt="copy logo"/>}    
        </CopyToClipboard>
    )
}

export default CopyButton
