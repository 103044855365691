import "./index.scss"

import React, {useEffect, useRef, useState} from "react"
import {useMediaQuery} from "react-responsive"

import WelcomePopup from "./Onboarding/WelcomePopup"
import AnniversaryPopup from "./Celebrations/AnniversaryPopup"
import BirthdayPopup from "./Celebrations/BirthdayPopup"

import {initialEvents} from "../../utils/greetings"

import {GREETING_TYPES, DESKTOP_WIDTH} from "../../constants/other"
import FlexiblePopup from "../../toolkits/FlexiblePopup"
import NewcoinsReceivedPopup from "./Newcoins/NewcoinsReceivedPopup"


const GreetingsPopup = ({userId, firstName, hiringDate, birthdate, close}) => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const wrapperRef = useRef(null)

    const [events, setEvents] = useState(
        initialEvents(userId, hiringDate, birthdate)
    )

    const closeFirstEvent = () => {
        setEvents(state => state.slice(1))
    }

    const handleClickOutside = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            closeFirstEvent()
        }
    }


    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        
        !events.length &&
            close()

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [events.length])


    const CurrentEvent = () => {
        switch (events[0].type) {
        case GREETING_TYPES.anniversary:
            return (
                <div className="greetings-wrapper">
                    <AnniversaryPopup
                        hiringDate={hiringDate}
                        close={closeFirstEvent}
                    />
                </div>
            )
        case GREETING_TYPES.welcome:
            return (
                <WelcomePopup
                    firstName={firstName}
                    close={closeFirstEvent}
                />
            )
        case GREETING_TYPES.birthday:
            return (
                <div className="greetings-wrapper">
                    <BirthdayPopup
                        birthdate={birthdate}
                        isDesktop={isDesktop}
                        close={closeFirstEvent}
                    />
                </div>
            )
        case GREETING_TYPES.newcoins:
            return (
                <div className="greetings-wrapper">
                    <NewcoinsReceivedPopup
                        reason={events[0].content.reason? events[0].content.reason : ""}
                        amount={events[0].content.amount}
                        close={closeFirstEvent}
                    />
                </div>

            )
        default:
            return (
                <></>
            )
        }
    }

    return (events.length ?
        <div ref={wrapperRef}>
            <FlexiblePopup noStyle={true}>
                <CurrentEvent/>
            </FlexiblePopup>
        </div> : <></>
    )
}

export default GreetingsPopup
