import {getTimeTrackerDetails} from "../api"
import {
    GET_TIME_DETAILS_REQUEST,
    GET_TIME_DETAILS_SUCCESS,
    GET_TIME_DETAILS_FAILURE
} from "../const"

const request = () => ({type: GET_TIME_DETAILS_REQUEST})

const success = (timeDetails) => ({
    type: GET_TIME_DETAILS_SUCCESS,
    payload: {timeDetails},
})

const failure = () => ({type: GET_TIME_DETAILS_FAILURE})

export const receiveTimeTrackerDetails = (userId, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: trackerDetailsData} = await getTimeTrackerDetails(userId, params)

        dispatch(success(trackerDetailsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
