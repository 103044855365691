import { getUserTimeRecordTemplates } from "../api"
import {
    GET_TIME_RECORD_TEMPLATES_REQUEST,
    GET_TIME_RECORD_TEMPLATES_SUCCESS,
    GET_TIME_RECORD_TEMPLATES_FAILURE
} from "../const"

const request = () => ({ type: GET_TIME_RECORD_TEMPLATES_REQUEST })

const success = (timeRecordTemplates) => ({
    type: GET_TIME_RECORD_TEMPLATES_SUCCESS,
    payload: { timeRecordTemplates },
})

const failure = () => ({ type: GET_TIME_RECORD_TEMPLATES_FAILURE })

export const receiveUserTimeRecordTemplates = (userId) => async (dispatch) => {
    dispatch(request())
    try {
        const { data: timeRecordTemplates } = await getUserTimeRecordTemplates(userId)

        dispatch(success(timeRecordTemplates))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}