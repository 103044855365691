import React from "react"
import {useDispatch} from "react-redux"

import {deleteTimeRecordById} from "../api"
import {receiveMyInfo} from "../../Profile/actions/userActions"


import CustomPopup from "../../../toolkits/CustomPopup/CustomPopup"
import SmallButton from "../../../toolkits/SmallButton/SmallButton"

import {SUCCESS_CODE, ERROR_CODE} from "../../../constants/other"


const DeleteTimeRecordPopup = ({timeRecord, refreshData, setUserMessage, close}) => {
    const dispatch = useDispatch()

    const handleSubmit = () => {
        deleteTimeRecordById(timeRecord.id)
            .then(() => {
                refreshData(new Date(timeRecord.date))
                setUserMessage({message: "Your time record was successfully deleted!", code: SUCCESS_CODE})

                // update profile data
                dispatch(receiveMyInfo())
            })
            .catch(error => {
                setUserMessage({message: error.response.data.message, code: ERROR_CODE})
            })
    }

    return (
        <CustomPopup text_title="Do you want to delete this time record?" isSubtitle isAgreePopup>
            <div className="popup-bottom">
                <div className="popup-bottom-button-container">
                    <SmallButton btnType="secondary" onClick={close}> CANCEL </SmallButton>
                    <SmallButton onClick={handleSubmit}> DELETE </SmallButton>
                </div>
            </div>
        </CustomPopup>
    )
}

export default DeleteTimeRecordPopup
