import {
    GET_EMPLOYEE_POSITIONS_REQUEST,
    GET_EMPLOYEE_POSITIONS_SUCCESS,
    GET_EMPLOYEE_POSITIONS_FAILURE,
    GET_NEXT_PAGE_EMPLOYEE_POSITIONS_REQUEST,
} from "../const"


const initialState = {
    employeePositions: [],
    total: 0,
    tableLoading: false,
}
  
const handlers = {
    [GET_EMPLOYEE_POSITIONS_REQUEST]: (state) => ({...state, tableLoading: true, employeePositions: [], total: 0}),
    [GET_EMPLOYEE_POSITIONS_SUCCESS]: (state, {payload: {employeePositions}}) => ({
        ...state,
        employeePositions: [...state.employeePositions, ...employeePositions.items],
        total: employeePositions.total,
        tableLoading: false,
    }),
    [GET_EMPLOYEE_POSITIONS_FAILURE]: (state) => ({...state, tableLoading: false}),
    
    [GET_NEXT_PAGE_EMPLOYEE_POSITIONS_REQUEST]: (state) => ({...state}),
    
    DEFAULT: (state) => state,
}

const employeePositionsReducer = (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT
    return handle(state, action)
}
  
export default employeePositionsReducer
