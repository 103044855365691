import React, {useContext, useState} from "react"
import Protected from "../../../../components/Protected/Protected"
import {PERMISSIONS} from "../../../../constants/permissions"
import {formatDate} from "../../../../utils/datetime.utils"
import {DD_MM_YYYY} from "../../../../constants/other"
import {EmptyCard} from "../../../../toolkits/EmptyResult/EmptyCard"
import Popup from "reactjs-popup"
import EditProjectsPopup from "../Popups/EditProjectsPopup"
import {receiveUserProjects} from "../../actions/projectsActions"
import PenIcon from "@mui/icons-material/Create"
import {useSelector} from "react-redux"
import {ThemeContext} from "../../../../styles/theme-context"

const ProjectsCard = ({projects, user, setUserMessage}) => {
    const [showEditProjectsPopup, setShowEditProjectsPopup] = useState(false)
    const {hasPermission} = useSelector((state) => state.profile)
    const theme = useContext(ThemeContext)

    return (
        <>
            <div className={`profile-right-projects ${hasPermission(PERMISSIONS.READ_PROJECT) ? theme.profileCardHover : ""}`}
                onClick={() => hasPermission(PERMISSIONS.READ_PROJECT) && setShowEditProjectsPopup(true)}>
                <span className="t-h3 profile-font-leading">PROJECTS</span>

                <div className="profile-info-card-links">
                    <Protected permissions={[PERMISSIONS.READ_PROJECT]}>
                        <Protected permissions={[PERMISSIONS.ASSIGN_PROJECT]}>
                            <PenIcon onClick={() => setShowEditProjectsPopup(true)} className="profile-info-card-pen-icon clickable"/>
                        </Protected>
                    </Protected>
                </div>

                {projects.length > 0 ?
                    <div  className="profile-right-projects-content">
                        {projects.map(project =>
                            <div key={project.id} className="t-s3 profile-right-projects-item">
                                <span className="t-s3 profile-right-projects-item-name">
                                    {project.project.name}
                                </span>
                                <span
                                    className="t-b4 profile-right-projects-item-date aligned-tabs"
                                >
                                    {`since ${formatDate(project.start_date, DD_MM_YYYY)}`}
                                </span>
                            </div>
                        )}
                    </div> : <EmptyCard>No projects</EmptyCard>
                }
            </div>

            <Popup open={showEditProjectsPopup} closeOnDocumentClick={false} onClose={() => setShowEditProjectsPopup(false)} modal>
                {close =>
                    <EditProjectsPopup
                        user={user}
                        receiveUserProjects={receiveUserProjects}
                        setUserMessage={setUserMessage}
                        close={close}
                    />
                }
            </Popup>
        </>
    )
}

export default ProjectsCard