import {getTimeOffRequests, getUserTimeOffRequests} from "../api"
import {
    GET_TIME_OFF_REQUESTS_REQUEST,
    GET_TIME_OFF_REQUESTS_SUCCESS,
    GET_TIME_OFF_REQUESTS_FAILURE,
    GET_NEXT_PAGE_TIME_OFF_REQUESTS_REQUEST
} from "../const"


const request = () => ({ type: GET_NEXT_PAGE_TIME_OFF_REQUESTS_REQUEST })

const searchRequest = () => ({ type: GET_TIME_OFF_REQUESTS_REQUEST })

const success = (timeOffRequests) => ({
    type: GET_TIME_OFF_REQUESTS_SUCCESS,
    payload: {timeOffRequests},
})
const failure = () => ({ type: GET_TIME_OFF_REQUESTS_FAILURE })


export const receiveTimeOffRequests= (params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveTimeOffRequestsData} = await getTimeOffRequests(params)

        dispatch(success(receiveTimeOffRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageTimeOffRequests = (params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveTimeOffRequestsData} = await getTimeOffRequests(params)

        dispatch(success(receiveTimeOffRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveUserTimeOffRequests = (userId, params) => async (dispatch) => {
    dispatch(searchRequest())
    try {
        const {data: receiveTimeOffRequestsData} = await getUserTimeOffRequests(userId, params)

        dispatch(success(receiveTimeOffRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageUserTimeOffRequests = (userId, params) => async (dispatch) => {
    dispatch(request())
    try {
        const {data: receiveTimeOffRequestsData} = await getUserTimeOffRequests(userId, params)

        dispatch(success(receiveTimeOffRequestsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
